import { DropDownItems } from '@/components/controls/CustomFormDropDown.tsx';
import { CreateFERTDeviceFormData } from '@/components/forms/CreateFERTDeviceForm.tsx';
import { CreateSensDeviceFormData } from '@/components/forms/CreateSensDeviceForm.tsx';
import { CreateStartDeviceFormData } from '@/components/forms/CreateStartDeviceForm.tsx';
import { EditFERTDeviceFormData } from '@/components/forms/EditFERTDeviceForm.tsx';
import { EditSensDeviceFormData } from '@/components/forms/EditSensDeviceForm.tsx';
import { EditStartDeviceFormData } from '@/components/forms/EditStartDeviceForm.tsx';
import {
  CreateFERTDeviceData,
  CreateSensDevice,
  CreateStartDeviceData,
  FERTDevice,
  SensDevice,
  StartDevice,
} from '@/redux/device/devices.types.ts';

export const itemsForRadioGroup = [
  { value: 'true', text: 'Uključeno' },
  { value: 'false', text: 'Isključeno' },
];

export const communicationModules: DropDownItems[] = [
  {
    value: 'GPRS',
    text: 'GPRS',
  },
  { value: 'LTE', text: 'LTE' },
  { value: 'LoRa', text: 'LoRa' },
];

export const sensDeviceTypes: DropDownItems[] = [
  {
    value: '30',
    text: '30 cm',
  },
  { value: '60', text: '60 cm' },
  { value: '90', text: '90 cm' },
];

export const transformFERTDeviceData = (
  data: CreateFERTDeviceFormData
): CreateFERTDeviceData => {
  return {
    parcelId: data.parcelId,
    name: data.name,
    color: data.color,
    zonesForDevice: data.zonesForDevice.map((zone) => Number(zone)),
    communicationModule: data.communicationModule,
    pHFactorK: Number(data.pHFactorK),
    pHFactorM: Number(data.pHFactorM),
    supplementOne: data.supplementOne === 'true',
    supplementTwo: data.supplementTwo === 'true',
    supplementThree: data.supplementThree === 'true',
    supplementFour: data.supplementFour === 'true',
    supplementFive: data.supplementFive === 'true',
    supplementMixer: data.supplementMixer === 'true',
    ecSensor: data.ecSensor === 'true',
    ecFactorK: Number(data.ecFactorK),
    flowMeter: data.flowMeter === 'true',
    notificationForWrongFlowMeter: JSON.parse(
      data.notificationForWrongFlowMeter
    ),
    pressureSensor: data.pressureSensor === 'true',
    waterLevelSensor: data.waterLevelSensor === 'true',
  };
};

export const transformStartDeviceData = (
  data: CreateStartDeviceFormData
): CreateStartDeviceData => {
  return {
    parcelId: data.parcelId,
    name: data.name,
    color: data.color,
    zonesForDevice: data.zonesForDevice.map((zone) => Number(zone)),
    humidityOne: data.humidityOne === 'true',
    humidityTwo: data.humidityTwo === 'true',
    supplementMixer: data.supplementMixer === 'true',
    flowMeter: data.flowMeter === 'true',
    notificationForWrongFlowMeter:
      data.notificationForWrongFlowMeter === 'true',
    pressureSensor: data.pressureSensor === 'true',
  };
};

export const transformStartDeviceForForm = (
  device: StartDevice
): EditStartDeviceFormData => {
  if (!device) {
    return null;
  }

  return {
    id: device.id,
    parcelId: device.parcelId,
    name: device.name,
    color: device.color,
    zonesForDevice: device.zonesForDevice.map((zone) => zone.toString()),
    humidityOne: device.humidity.humidityOne.toString(),
    humidityTwo: device.humidity.humidityTwo.toString(),
    supplementMixer: device.additionalSensorValues.supplementMixer.toString(),
    flowMeter: device.additionalSensorValues.flowMeter.toString(),
    notificationForWrongFlowMeter:
      device.additionalSensorValues.notificationForWrongFlowMeter.toString(),
    pressureSensor: device.additionalSensorValues.pressureSensor.toString(),
  };
};

export const transformEditStartDeviceData = (
  data: EditStartDeviceFormData
): StartDevice => {
  return {
    id: data.id,
    parcelId: data.parcelId,
    name: data.name,
    color: data.color,
    zonesForDevice: data.zonesForDevice.map((zone) => Number(zone)),
    humidity: {
      humidityOne: data.humidityOne === 'true',
      humidityTwo: data.humidityTwo === 'true',
    },
    additionalSensorValues: {
      supplementMixer: data.supplementMixer === 'true',
      flowMeter: data.flowMeter === 'true',
      notificationForWrongFlowMeter:
        data.notificationForWrongFlowMeter === 'true',
      pressureSensor: data.pressureSensor === 'true',
    },
  };
};

export const transformFERTDeviceForForm = (
  device: FERTDevice
): EditFERTDeviceFormData => {
  if (!device) {
    return null;
  }

  return {
    id: device.id,
    parcelId: device.parcelId,
    name: device.name,
    color: device.color,
    communicationModule: device.communicationModule,
    zonesForDevice: device.zonesForDevice.map((zone) => zone.toString()),
    supplementMixer: device.additionalSensorsValues.supplementMixer.toString(),
    flowMeter: device.additionalSensorsValues.flowMeter.toString(),
    notificationForWrongFlowMeter:
      device.additionalSensorsValues.notificationForWrongFlowMeter.toString(),
    pressureSensor: device.additionalSensorsValues.pressureSensor.toString(),
    pHFactorK: device.pHFactorK.toString(),
    pHFactorM: device.pHFactorM.toString(),
    supplementOne: device.supplement.supplementOne.toString(),
    supplementTwo: device.supplement.supplementTwo.toString(),
    supplementThree: device.supplement.supplementThree.toString(),
    supplementFour: device.supplement.supplementFour.toString(),
    supplementFive: device.supplement.supplementFive.toString(),
    ecSensor: device.additionalSensorsValues.ecSensor.toString(),
    ecFactorK: device.additionalSensorsValues.ecFactorK.toString(),
    waterLevelSensor:
      device.additionalSensorsValues.waterLevelSensor.toString(),
  };
};

export const transformEditFERTDeviceData = (
  data: EditFERTDeviceFormData
): FERTDevice => {
  return {
    id: data.id,
    parcelId: data.parcelId,
    name: data.name,
    color: data.color,
    communicationModule: data.communicationModule,
    pHFactorK: Number(data.pHFactorK),
    pHFactorM: Number(data.pHFactorM),
    additionalSensorsValues: {
      supplementMixer: data.supplementMixer === 'true',
      ecSensor: data.ecSensor === 'true',
      waterLevelSensor: data.waterLevelSensor === 'true',
      pressureSensor: data.waterLevelSensor === 'true',
      notificationForWrongFlowMeter:
        data.notificationForWrongFlowMeter === 'true',
      flowMeter: data.flowMeter === 'true',
      ecFactorK: Number(data.ecFactorK),
    },
    zonesForDevice: data.zonesForDevice.map((zone) => Number(zone)),
    supplement: {
      supplementOne: data.supplementOne === 'true',
      supplementTwo: data.supplementTwo === 'true',
      supplementThree: data.supplementThree === 'true',
      supplementFour: data.supplementFour === 'true',
      supplementFive: data.supplementFive === 'true',
    },
  };
};

export const transformSensDeviceData = (
  data: CreateSensDeviceFormData
): CreateSensDevice => {
  return {
    parcelId: data.parcelId,
    name: data.name,
    color: data.color,
    zonesForDevice: data.zonesForDevice.map((zone) => Number(zone)),
    sensSensorType: Number(data.sensSensorType),
    standbyModeTime: Number(data.standbyModeTime),
    humidityOne: data.humidityOne === 'true',
    humidityTwo: data.humidityTwo === 'true',
    flowMeter: data.flowMeter === 'true',
    soilTemperature: data.soilTemperature === 'true',
    soilSalinity: data.soilSalinity === 'true',
    soilPH: data.soilPH === 'true',
    airTemperature: data.airTemperature === 'true',
    airHumidity: data.airHumidity === 'true',
    windDirection: data.windDirection === 'true',
    windSpeed: data.windSpeed === 'true',
    solarRadiation: data.solarRadiation === 'true',
    amountOfPrecipitation: data.amountOfPrecipitation === 'true',
    batteryVoltage: data.batteryVoltage === 'true',
    notificationForWrongFlowMeter:
      data.notificationForWrongFlowMeter === 'true',
    pressureSensor: data.pressureSensor === 'true',
  };
};

export const transformSensDeviceForForm = (
  device: SensDevice
): EditSensDeviceFormData => {
  if (!device) {
    return null;
  }

  return {
    id: device.id,
    parcelId: device.parcelId,
    name: device.name,
    color: device.color,
    zonesForDevice: device.zonesForDevice.map((zone) => zone.toString()),
    standbyModeTime: device.standbyModeTime.toString(),
    sensSensorType: device.sensSensorType.toString(),
    humidityOne: device.humidity.humidityOne.toString(),
    humidityTwo: device.humidity.humidityTwo.toString(),
    flowMeter: device.additionalSensorsValues.flowMeter.toString(),
    soilTemperature: device.additionalSensorsValues.soilTemperature.toString(),
    soilSalinity: device.additionalSensorsValues.soilSalinity.toString(),
    soilPH: device.additionalSensorsValues.soilPH.toString(),
    airTemperature: device.additionalSensorsValues.airTemperature.toString(),
    airHumidity: device.additionalSensorsValues.airHumidity.toString(),
    windDirection: device.additionalSensorsValues.windDirection.toString(),
    windSpeed: device.additionalSensorsValues.windSpeed.toString(),
    solarRadiation: device.additionalSensorsValues.solarRadiation.toString(),
    amountOfPrecipitation:
      device.additionalSensorsValues.amountOfPrecipitation.toString(),
    batteryVoltage: device.additionalSensorsValues.batteryVoltage.toString(),
    notificationForWrongFlowMeter:
      device.additionalSensorsValues.notificationForWrongFlowMeter.toString(),
    pressureSensor: device.additionalSensorsValues.pressureSensor.toString(),
  };
};

export const transformEditSensDeviceData = (
  data: EditSensDeviceFormData
): SensDevice => {
  return {
    id: data.id,
    parcelId: data.parcelId,
    name: data.name,
    color: data.color,
    sensSensorType: Number(data.sensSensorType),
    standbyModeTime: Number(data.standbyModeTime),
    additionalSensorsValues: {
      flowMeter: data.flowMeter === 'true',
      pressureSensor: data.pressureSensor === 'true',
      airHumidity: data.airHumidity === 'true',
      soilPH: data.soilPH === 'true',
      notificationForWrongFlowMeter:
        data.notificationForWrongFlowMeter === 'true',
      airTemperature: data.airTemperature === 'true',
      amountOfPrecipitation: data.amountOfPrecipitation === 'true',
      batteryVoltage: data.batteryVoltage === 'true',
      soilSalinity: data.soilSalinity === 'true',
      soilTemperature: data.soilTemperature === 'true',
      solarRadiation: data.solarRadiation === 'true',
      windDirection: data.windDirection === 'true',
      windSpeed: data.windSpeed === 'true',
    },
    zonesForDevice: data.zonesForDevice.map((zone) => Number(zone)),
    humidity: {
      humidityOne: data.humidityOne === 'true',
      humidityTwo: data.humidityTwo === 'true',
    },
  };
};

const compareByValues = (a: any, b: any) => {
  return a.value - b.value;
};

export const formAvailableZonesContent = (
  device: StartDevice | FERTDevice | SensDevice,
  availableZones: number[]
) => {
  const transformedAvailableZones = availableZones.map((zone) => {
    return {
      label: `Zona ${zone}`,
      value: zone.toString(),
    };
  });

  if (!device) {
    return transformedAvailableZones;
  }

  const deviceZones = device.zonesForDevice.map((zone) => {
    return {
      label: `Zona ${zone}`,
      value: zone.toString(),
    };
  });

  return [...transformedAvailableZones, ...deviceZones].sort(compareByValues);
};
