import { FC } from 'react';

const Account: FC = () => {
  return (
    <div className="flex flex-col items-center h-[60vh] justify-center">
      <h1 className="text-size-64 mb-10 text-lightBlue">404</h1>
      <h2 className="text-size-42 text-lightBlue">Page not found</h2>
    </div>
  );
};

export default Account;
