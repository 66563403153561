import { FC, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import AddUserForDistributorForm from '@/components/forms/AddUserForDistributorForm.tsx';
import UsersForDistributorSection from '@/components/layout/distributors/UsersForDistributorSection.tsx';
import { logoutState, setName } from '@/redux/auth/auth.slice.ts';
import { useAppDispatch } from '@/redux/hooks.ts';
import { getUsersForDropdown } from '@/redux/users/users.actions.ts';
import { GetUserDropdownResponse } from '@/redux/users/users.types.ts';

const EditDistributorsUserSection: FC = () => {
  const dispatch = useAppDispatch();
  const router = useNavigate();

  const handleErrorResponse = (response: GetUserDropdownResponse) => {
    if (response.error.removeUser) {
      localStorage.removeItem('token');
      localStorage.removeItem('name');
      dispatch(logoutState());
      dispatch(setName(''));
      router('/login');
      return;
    }

    toast.error(response.error.message);
  };

  useEffect(() => {
    const fetchUsersForDropdown = async () => {
      // @ts-ignore
      const response = await dispatch(getUsersForDropdown()).unwrap();

      if (!response.success) {
        handleErrorResponse(response);
      }
    };

    fetchUsersForDropdown();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="flex flex-col items-start gap-6 p-10 max-md:p-5">
      <div className="bg-white w-full shadow-lg flex flex-col md:gap-5">
        <div className="w-full px-5">
          <div className="flex flex-row md:gap-5 ">
            <div className="w-[50%] pt-5 max-md:w-full">
              <h2 className="text-lg font-bold">Dodaj novog korisnika</h2>
              <AddUserForDistributorForm />
            </div>
            <div className="w-[50%] max-md:hidden" />
          </div>
        </div>
      </div>

      <div className="bg-white w-full shadow-lg">
        <UsersForDistributorSection />
      </div>
    </div>
  );
};

export default EditDistributorsUserSection;
