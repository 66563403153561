import { FC } from 'react';

import AddSortForm from '@/components/forms/AddSortForm.tsx';
import FruitSortSection from '@/components/layout/fruits/FruitSortSection.tsx';

const FruitSortContent: FC = () => {
  return (
    <div className="flex items-start gap-6 p-10 max-md:p-5">
      <div className="bg-white w-full shadow-lg flex flex-col md:gap-5">
        <div className="w-full px-5">
          <div className="flex flex-row md:gap-5 border-b-2 ">
            <div className="w-[50%] pt-5 max-md:w-full">
              <h2 className="text-2xl font-bold">Sorte</h2>
              <AddSortForm />
            </div>
            <div className="w-[50%] max-md:hidden" />
          </div>
        </div>
        <div className="w-full px-5">
          <h2 className="text-2xl font-bold">Sve sorte</h2>
          <FruitSortSection />
        </div>
      </div>
    </div>
  );
};

export default FruitSortContent;
