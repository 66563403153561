import { ArrowUpDown } from 'lucide-react';
import { FC } from 'react';

import { Button } from '@/components/ui/button.tsx';
import { TableHead, TableHeader, TableRow } from '@/components/ui/table.tsx';

export type TableHeaderType = {
  id: string;
  text: string;
  className: string;
  isSortDisabled?: boolean;
};

type CustomTableHeaderProps = {
  tableName: string;
  tableHeaders: TableHeaderType[];
  onHeaderCellClick: (tableHeaderId: string) => void;
};

const CustomTableHeader: FC<CustomTableHeaderProps> = ({
  tableName,
  tableHeaders,
  onHeaderCellClick,
}) => {
  const onColumnClick = (tableHeader: TableHeaderType) => {
    if (tableHeader.isSortDisabled) {
      return;
    }

    onHeaderCellClick(tableHeader.id);
  };

  return (
    <TableHeader>
      <TableRow>
        {tableHeaders.map((tableHeader, index) => (
          <TableHead
            key={`${tableName}-table-header-${tableHeader.id}-${index + 1}`}
            className={tableHeader.className}
          >
            <Button variant="ghost" onClick={() => onColumnClick(tableHeader)}>
              {tableHeader.text}
              {!tableHeader.isSortDisabled && (
                <ArrowUpDown className="ml-2 h-4 w-4" />
              )}
            </Button>
          </TableHead>
        ))}
      </TableRow>
    </TableHeader>
  );
};

export default CustomTableHeader;
