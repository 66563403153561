import { zodResolver } from '@hookform/resolvers/zod';
import { FC } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import CustomFormDropDown, {
  DropDownItems,
} from '@/components/controls/CustomFormDropDown.tsx';
import CustomFormInput from '@/components/controls/CustomFormInput.tsx';
import { Button } from '@/components/ui/button.tsx';
import { Form } from '@/components/ui/form.tsx';
import { logoutState, setName } from '@/redux/auth/auth.slice.ts';
import { getAllFruitSorts } from '@/redux/fruitSort/fruitSort.actions.ts';
import { createFruitSort } from '@/redux/fruitSort/fruitSort.actions.ts';
import {
  CreateFruitSortResponse,
  GetFruitSortsResponse,
} from '@/redux/fruitSort/fruitSort.types.ts';
import { getFruitTypes } from '@/redux/fruitType/fruitType.selectors.ts';
import { useAppDispatch, useAppSelector } from '@/redux/hooks.ts';
import { createFruitSortSchema } from '@/zod/types.ts';

export type CreateSortFormData = {
  name: string;
  fruitTypeId: string;
};

const AddSortForm: FC = () => {
  const fruitTypes = useAppSelector(getFruitTypes);
  const dropdownContent: DropDownItems[] = fruitTypes.map((fruitType) => {
    return {
      value: fruitType.id,
      text: fruitType.name,
    };
  });
  const form = useForm({
    resolver: zodResolver(createFruitSortSchema),
    defaultValues: {
      name: '',
      fruitTypeId: '',
    },
  });
  const dispatch = useAppDispatch();
  const router = useNavigate();

  const handleErrorResponse = (
    response: CreateFruitSortResponse | GetFruitSortsResponse
  ) => {
    if (response.error.removeUser) {
      localStorage.removeItem('token');
      localStorage.removeItem('name');
      dispatch(logoutState());
      dispatch(setName(''));
      router('/login');
      return;
    }

    toast.error(response.error.message);
  };

  const onSubmit = async (data: CreateSortFormData) => {
    // // @ts-ignore
    const response = await dispatch(createFruitSort(data)).unwrap();
    if (!response.success) {
      handleErrorResponse(response);
      return;
    }

    form.setValue('name', '');
  };

  const onTypeChangeFunction = async (fruitTypeId: string) => {
    // @ts-ignore
    const response = await dispatch(getAllFruitSorts(fruitTypeId)).unwrap();

    if (!response.success) {
      handleErrorResponse(response);
    }
  };

  return (
    <Form
      reset={form.reset}
      formState={form.formState}
      clearErrors={form.clearErrors}
      control={form.control}
      getFieldState={form.getFieldState}
      getValues={form.getValues}
      handleSubmit={form.handleSubmit}
      register={form.register}
      resetField={form.resetField}
      setError={form.setError}
      setFocus={form.setFocus}
      setValue={form.setValue}
      trigger={form.trigger}
      unregister={form.unregister}
      watch={form.watch}
    >
      <form
        className="flex items-start flex-col mt-5 max-md:mt-2 pb-10 max-md:pb-5"
        onSubmit={form.handleSubmit(onSubmit)}
      >
        <CustomFormDropDown
          customForm={form}
          name="fruitTypeId"
          labelText="Vrsta"
          formItemStyle="flex flex-col w-full max-md:mt-2"
          items={dropdownContent}
          placeholder="Odaberite vrstu"
          onChangeFunction={onTypeChangeFunction}
        />
        <CustomFormInput
          customForm={form}
          name="name"
          formItemStyle="flex flex-col max-md:flex-col w-full pb-5 max-md:py-5 space-y-0 mt-5 max-md:mt-2"
          divStyle="w-full"
          type="text"
          placeholder="Sorta"
          inputStyle="py-3 px-2"
          labelText="Ime Sorte"
          labelStyle="mb-2"
        />
        <Button className="bg-[#007bff] py-3 px-2 text-white" type="submit">
          Dodaj sortu
        </Button>
      </form>
    </Form>
  );
};

export default AddSortForm;
