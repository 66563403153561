import { FC, useState } from 'react';

import EditDistributorInfoForm from '@/components/forms/EditDistributorInfoForm.tsx';
import EditDistributorsUserSection from '@/components/layout/distributors/EditDistributorsUserSection.tsx';
import { Button } from '@/components/ui/button.tsx';

export type EditDistributorFormData = {
  id: string;
  name: string;
  email: string;
  phoneNumber: string;
  address: string;
  place: string;
  country: string;
};

enum EditDistributorTab {
  INFO,
  USERS,
}

type TabButtonProps = {
  buttonText: string;
  tabForButton: EditDistributorTab;
  activeTab: EditDistributorTab;
  onTabClick: (tab: EditDistributorTab) => void;
};

const TabButton: FC<TabButtonProps> = ({
  buttonText,
  tabForButton,
  activeTab,
  onTabClick,
}) => {
  const onClick = () => {
    onTabClick(tabForButton);
  };

  const getStylesForTabButton = () => {
    const styles = 'py-3 px-3';

    if (activeTab === tabForButton) {
      return `bg-[#2a90d9] text-white ${styles}`;
    }

    return `bg-white hover:bg-gray-200 text-[#212529] border-[1px] border-[#2a90d9] ${styles}`;
  };

  return (
    <Button className={getStylesForTabButton()} onClick={onClick}>
      {buttonText}
    </Button>
  );
};

const EditDistributorTabs: FC = () => {
  const [activeTab, setActiveTab] = useState<EditDistributorTab>(
    EditDistributorTab.INFO
  );

  const onTabClick = (clickedTab: EditDistributorTab) => {
    setActiveTab(clickedTab);
  };

  return (
    <div className="flex w-[88%] flex-col bg-[#85b4f2] bg-opacity-10 max-md:w-full">
      <nav className="flex itemst-start bg-white border-b-2 w-full px-10 py-5 max-md:px-5">
        <div className="text-3xl font-bold flex flex-row gap-2">
          <TabButton
            buttonText="Info"
            tabForButton={EditDistributorTab.INFO}
            activeTab={activeTab}
            onTabClick={onTabClick}
          />
          <TabButton
            buttonText="Korisnici"
            tabForButton={EditDistributorTab.USERS}
            activeTab={activeTab}
            onTabClick={onTabClick}
          />
        </div>
      </nav>
      {activeTab === EditDistributorTab.INFO && <EditDistributorInfoForm />}
      {activeTab === EditDistributorTab.USERS && (
        <EditDistributorsUserSection />
      )}
    </div>
  );
};

export default EditDistributorTabs;
