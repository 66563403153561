import { FetchParcel } from '@/redux/parcels/parcels.types.ts';
import { RootState } from '@/redux/store.ts';

export const getParcels = (state: RootState): FetchParcel[] =>
  state.reducer.parcel.parcels || [];

export const getTotalNumberOfParcels = (state: RootState): number =>
  state.reducer.parcel.totalNumberOfParcels || 0;

export const getFilteredNumberOfParcels = (state: RootState): number =>
  state.reducer.parcel.filteredNumberOfParcels || 0;
