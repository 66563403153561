import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { FruitType } from '@/redux/fruitType/fruitType.types.ts';

type FruitTypesInitialState = {
  fruitTypes: FruitType[];
};

const fruitTypesInitialState: FruitTypesInitialState = {
  fruitTypes: [],
};

const fruitTypesSlice = createSlice({
  name: 'fruitTypesSlice',
  initialState: fruitTypesInitialState,
  reducers: {
    addFruitType: (state, action: PayloadAction<FruitType>) => {
      state.fruitTypes = [...state.fruitTypes, action.payload];
    },
    setFruitTypes: (state, action: PayloadAction<FruitType[]>) => {
      state.fruitTypes = action.payload || [];
    },
    removeFruitType: (state, action: PayloadAction<string>) => {
      state.fruitTypes = state.fruitTypes.filter(
        (fruitType) => fruitType.id !== action.payload
      );
    },
  },
});

export const { addFruitType, setFruitTypes, removeFruitType } =
  fruitTypesSlice.actions;
export default fruitTypesSlice.reducer;
