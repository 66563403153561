import { FC } from 'react';

import DistributorsTableSection from '@/components/layout/distributors/DistributorsTableSection.tsx';

const DistributorsPageContent: FC = () => {
  return (
    <div className="flex w-[88%] flex-col bg-[#85b4f2] bg-opacity-10 max-md:w-full max-md:pb-20">
      <nav className="flex itemst-start bg-white border-b-2 w-full px-10 py-5 max-md:px-5">
        <div className="text-3xl font-bold">Distributeri</div>
      </nav>
      <div className="flex items-start gap-6 p-10 max-md:p-5">
        <div className="bg-white w-full shadow-lg">
          <nav className="flex items-start bg-white border-b-2 w-full px-5 py-3">
            <div className="text-xl font-bold">Svi distributeri</div>
          </nav>
          <DistributorsTableSection />
        </div>
      </div>
    </div>
  );
};

export default DistributorsPageContent;
