import { FC } from 'react';

import AddNewIcon from '@/components/controls/AddNewIcon.tsx';
import MobileFooterNavigation from '@/components/layout/MobileFooterNavigation.tsx';
import Navbar from '@/components/layout/Navbar.tsx';
import ParcelsPageContent from '@/components/layout/parcels/ParcelsPageContent.tsx';
import Sidebar from '@/components/layout/Sidebar.tsx';

const ParcelsPage: FC = () => {
  return (
    <div className="flex h-screen w-full flex-col">
      <Navbar />
      <div className="flex flex-1 flex-row">
        <Sidebar />
        <ParcelsPageContent />
        <AddNewIcon link="/parcels/create" />
      </div>
      <MobileFooterNavigation />
    </div>
  );
};

export default ParcelsPage;
