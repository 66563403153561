import {
  FERTDevice,
  FetchDevice,
  SensDevice,
  StartDevice,
} from '@/redux/device/devices.types.ts';
import { RootState } from '@/redux/store.ts';

export const getAvailableZones = (state: RootState): number[] =>
  state.reducer.device.availableZones || [];

export const getDevicesForParcel = (state: RootState): FetchDevice[] =>
  state.reducer.device.devices || [];

export const getStartDeviceForEdit = (state: RootState): StartDevice =>
  state.reducer.device.startDeviceForEdit || null;

export const getFERTDeviceForEdit = (state: RootState): FERTDevice =>
  state.reducer.device.fertDeviceForEdit || null;

export const getSensDeviceForEdit = (state: RootState): SensDevice =>
  state.reducer.device.sensDeviceForEdit || null;
