import debounce from 'lodash/debounce';
import React, { ChangeEvent, FC, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import CustomTableHeader, {
  TableHeaderType,
} from '@/components/layout/CustomTableHeader.tsx';
import PaginationSection from '@/components/layout/PaginationSection.tsx';
import SelectUserParcelDialog from '@/components/layout/users/SelectUserParcelDialog.tsx';
import { Input } from '@/components/ui/input.tsx';
import {
  Table,
  TableBody,
  TableCell,
  TableRow,
} from '@/components/ui/table.tsx';
import { config } from '@/config/config.ts';
import { logoutState, setName } from '@/redux/auth/auth.slice.ts';
import { useAppDispatch, useAppSelector } from '@/redux/hooks.ts';
import {
  getFilteredNumberOfUsers,
  getTotalNumberOfUsers,
  getUsers,
} from '@/redux/users/user.selectors.ts';
import {
  getFilteredUsers,
  getParcelsForUser,
} from '@/redux/users/users.actions.ts';
import {
  GetParcelsForUserResponse,
  GetUsersResponse,
} from '@/redux/users/users.types.ts';
import { formatCreationDate, formatPaymentDate } from '@/utils/dateUtil.ts';

const userTableHeaders: TableHeaderType[] = [
  {
    id: 'full_name',
    text: 'Korisnik',
    className: '',
  },
  {
    id: 'place',
    text: 'Mesto',
    className: '',
  },
  {
    id: 'country',
    text: 'Drzava',
    className: 'max-md:hidden',
  },
  { id: 'email', text: 'Email', className: '' },
  {
    id: 'phoneNumber',
    text: 'Telefon',
    className: 'max-md:hidden',
    isSortDisabled: true,
  },
  { id: 'parcelCount', text: 'Broj parcela', className: 'max-md:hidden' },
  { id: 'createdAt', text: 'Datum kreiranja', className: 'max-md:hidden' },
  { id: 'dateOfPayment', text: 'Datum plaćanja', className: 'max-md:hidden' },
];

const UsersTableSection: FC = () => {
  const router = useNavigate();
  const users = useAppSelector(getUsers);
  const totalNumberOfUsers = useAppSelector(getTotalNumberOfUsers);
  const filteredNumberOfUsers = useAppSelector(getFilteredNumberOfUsers);
  const dispatch = useAppDispatch();
  const [page, setPage] = useState<number>(0);
  const rowsPerPage = 10;
  const [sortColumn, setSortColumn] = useState<string>('');
  const [isSortAsc, setIsSortAsc] = useState<boolean>(true);
  const [inputText, setInputText] = useState<string>('');
  const [parcelDialogOpened, setParcelDialogOpened] = useState(false);

  const createQueryParams = (
    realPage: number,
    realSortColumn: string,
    realSortAsc: boolean,
    realInputText: string
  ) => {
    return `?page=${realPage}&perPage=${rowsPerPage}${realSortColumn ? `&sortColumn=${realSortColumn}&sortDirection=${realSortAsc ? 'ASC' : 'DESC'}` : ''}${realInputText ? `&inputText=${realInputText}` : ''}`;
  };

  const callFetchUsers = async (queryParams: string) => {
    // @ts-ignore
    return dispatch(getFilteredUsers(queryParams)).unwrap();
  };

  const handleErrorResponse = (
    response: GetUsersResponse | GetParcelsForUserResponse
  ) => {
    if (response.error.removeUser) {
      localStorage.removeItem('token');
      localStorage.removeItem('name');
      dispatch(logoutState());
      dispatch(setName(''));
      router('/login');
      return;
    }

    toast.error(response.error.message);
  };

  const onDebounceCall = async (value: string) => {
    setInputText(value);
    setPage(0);
    const queryParams = createQueryParams(0, sortColumn, isSortAsc, value);
    await callFetchUsers(queryParams);
  };

  const debouncedChangeHandler = debounce((value: string) => {
    onDebounceCall(value);
  }, 500);

  const handleChange = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { value } = e.target;
    debouncedChangeHandler(value);
  };

  const onColumnClick = async (columnName: string) => {
    if (columnName === sortColumn) {
      setIsSortAsc(!isSortAsc);
      const queryParams = createQueryParams(
        page,
        sortColumn,
        !isSortAsc,
        inputText
      );
      await callFetchUsers(queryParams);
      return;
    }

    setSortColumn(columnName);
    setIsSortAsc(true);
    const queryParams = createQueryParams(page, columnName, true, inputText);
    await callFetchUsers(queryParams);
  };

  const onPageChangeClick = async (selectedPage: number) => {
    const queryParams = createQueryParams(
      selectedPage,
      sortColumn,
      isSortAsc,
      inputText
    );
    setPage(selectedPage);
    await callFetchUsers(queryParams);
  };

  const onUserClick = (userId: string) => {
    router(`/users/edit/${userId}`);
  };

  const onParcelColumnClick = async (
    e: React.MouseEvent<HTMLTableCellElement>,
    userId: string
  ) => {
    e.stopPropagation();
    // @ts-ignore
    const response = await dispatch(getParcelsForUser(userId)).unwrap();
    if (!response.success) {
      handleErrorResponse(response);
      return;
    }

    if (response.content.length === 0) {
      toast.info('Korisnik nema ni jednu parcelu');
      return;
    }

    if (response.content.length === 1) {
      router(`/parcels/edit/${response.content[0].id}`);
      return;
    }

    setParcelDialogOpened(true);
  };

  const onUserEmailClick = async (
    e: React.MouseEvent<HTMLTableCellElement>,
    userEmail: string
  ) => {
    e.stopPropagation();
    window.open(
      `${config.userAppUrl}?email=${userEmail}&token=${localStorage.getItem('token')}&isAdmin=true`,
      '_blank'
    );
  };

  useEffect(() => {
    const fetchUsers = async () => {
      const queryParams = createQueryParams(
        page,
        sortColumn,
        isSortAsc,
        inputText
      );
      const response = await callFetchUsers(queryParams);
      if (!response.success) {
        handleErrorResponse(response);
      }
    };

    fetchUsers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <div className="pt-5 px-5 pb-2 flex flex-row items-center justify-end gap-5">
        <p>Pretraga:</p>
        <div>
          <Input className="rounded-0" onChange={handleChange} />
        </div>
      </div>
      <div className="border-b border-gray-900/10 pt-5 md:px-5 text-sm">
        <div className="flex flex-col">
          <Table>
            <CustomTableHeader
              tableName="users"
              tableHeaders={userTableHeaders}
              onHeaderCellClick={onColumnClick}
            />
            <TableBody>
              {users.map((user, index) => (
                <TableRow
                  key={`user-table-data-user-${index + 1}`}
                  className="cursor-pointer"
                  onClick={() => onUserClick(user.id)}
                >
                  <TableCell>{user.full_name}</TableCell>
                  <TableCell>{user.place}</TableCell>
                  <TableCell className="max-md:hidden">
                    {user.country}
                  </TableCell>
                  <TableCell
                    className="hover:text-[#007bff] hover:underline"
                    onClick={(e) => onUserEmailClick(e, user.email)}
                  >
                    {user.email}
                  </TableCell>
                  <TableCell className="max-md:hidden">
                    {user.phoneNumber}
                  </TableCell>
                  <TableCell
                    className="max-md:hidden"
                    onClick={(e) => onParcelColumnClick(e, user.id)}
                  >
                    {user.parcelCount}
                  </TableCell>
                  <TableCell className="max-md:hidden">
                    {formatCreationDate(new Date(user.createdAt))}
                  </TableCell>
                  <TableCell className="max-md:hidden">
                    {formatPaymentDate(new Date(user.dateOfPayment))}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </div>
      </div>
      <PaginationSection
        page={page}
        rowsPerPage={rowsPerPage}
        filteredNumber={filteredNumberOfUsers}
        totalNumber={totalNumberOfUsers}
        onPageChangeClick={onPageChangeClick}
        entity="korisnika"
      />
      <SelectUserParcelDialog
        isDialogOpen={parcelDialogOpened}
        setIsDialogOpen={setParcelDialogOpened}
      />
    </>
  );
};

export default UsersTableSection;
