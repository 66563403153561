import debounce from 'lodash/debounce';
import { ChangeEvent, FC, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import CustomTableHeader, {
  TableHeaderType,
} from '@/components/layout/CustomTableHeader.tsx';
import PaginationSection from '@/components/layout/PaginationSection.tsx';
import { Input } from '@/components/ui/input.tsx';
import {
  Table,
  TableBody,
  TableCell,
  TableRow,
} from '@/components/ui/table.tsx';
import { logoutState, setName } from '@/redux/auth/auth.slice.ts';
import { getFilteredDistributors } from '@/redux/distributors/distributors.actions.ts';
import {
  getDistributors,
  getFilteredNumberOfDistributors,
  getTotalNumberOfDistributors,
} from '@/redux/distributors/distributors.selectors.ts';
import { GetDistributorsResponse } from '@/redux/distributors/distributors.types.ts';
import { useAppDispatch, useAppSelector } from '@/redux/hooks.ts';
import { formatCreationDate } from '@/utils/dateUtil.ts';

const distributorsTableHeaders: TableHeaderType[] = [
  {
    id: 'name',
    text: 'Distributer',
    className: '',
  },
  {
    id: 'place',
    text: 'Mesto',
    className: '',
  },
  { id: 'email', text: 'Email', className: '' },
  { id: 'phoneNumber', text: 'Telefon', className: 'max-md:hidden' },
  { id: 'parcelCount', text: 'Broj korisnika', className: 'max-md:hidden' },
  { id: 'createdAt', text: 'Datum kreiranja', className: 'max-md:hidden' },
];

const DistributorsTableSection: FC = () => {
  const router = useNavigate();
  const distributors = useAppSelector(getDistributors);
  const totalNumberOfDistributors = useAppSelector(
    getTotalNumberOfDistributors
  );
  const filteredNumberOfDistributors = useAppSelector(
    getFilteredNumberOfDistributors
  );
  const dispatch = useAppDispatch();
  const [page, setPage] = useState<number>(0);
  const rowsPerPage = 10;
  const [sortColumn, setSortColumn] = useState<string>('');
  const [isSortAsc, setIsSortAsc] = useState<boolean>(true);
  const [inputText, setInputText] = useState<string>('');

  const createQueryParams = (
    realPage: number,
    realSortColumn: string,
    realSortAsc: boolean,
    realInputText: string
  ) => {
    return `?page=${realPage}&perPage=${rowsPerPage}${realSortColumn ? `&sortColumn=${realSortColumn}&sortDirection=${realSortAsc ? 'ASC' : 'DESC'}` : ''}${realInputText ? `&inputText=${realInputText}` : ''}`;
  };

  const callFetchDistributors = async (queryParams: string) => {
    // @ts-ignore
    return dispatch(getFilteredDistributors(queryParams)).unwrap();
  };

  const handleErrorResponse = (response: GetDistributorsResponse) => {
    if (response.error.removeUser) {
      localStorage.removeItem('token');
      localStorage.removeItem('name');
      dispatch(logoutState());
      dispatch(setName(''));
      router('/login');
      return;
    }

    toast.error(response.error.message);
  };

  const onDebounceCall = async (value: string) => {
    setInputText(value);
    setPage(0);
    const queryParams = createQueryParams(0, sortColumn, isSortAsc, value);
    await callFetchDistributors(queryParams);
  };

  const debouncedChangeHandler = debounce((value: string) => {
    onDebounceCall(value);
  }, 500);

  const handleChange = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { value } = e.target;
    debouncedChangeHandler(value);
  };

  const onColumnClick = async (columnName: string) => {
    if (columnName === sortColumn) {
      setIsSortAsc(!isSortAsc);
      const queryParams = createQueryParams(
        page,
        sortColumn,
        !isSortAsc,
        inputText
      );
      await callFetchDistributors(queryParams);
      return;
    }

    setSortColumn(columnName);
    setIsSortAsc(true);
    const queryParams = createQueryParams(page, columnName, true, inputText);
    await callFetchDistributors(queryParams);
  };

  const onPageChangeClick = async (selectedPage: number) => {
    const queryParams = createQueryParams(
      selectedPage,
      sortColumn,
      isSortAsc,
      inputText
    );
    setPage(selectedPage);
    await callFetchDistributors(queryParams);
  };

  const onDistributorClick = (userId: string) => {
    router(`/distributors/edit/${userId}`);
  };

  useEffect(() => {
    const fetchDistributors = async () => {
      const queryParams = createQueryParams(
        page,
        sortColumn,
        isSortAsc,
        inputText
      );
      const response = await callFetchDistributors(queryParams);
      if (!response.success) {
        handleErrorResponse(response);
      }
    };

    fetchDistributors();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <div className="pt-5 px-5 pb-2 flex flex-row items-center justify-end gap-5">
        <p>Pretraga:</p>
        <div>
          <Input className="rounded-0" onChange={handleChange} />
        </div>
      </div>
      <div className="border-b border-gray-900/10 pt-5 md:px-5 text-sm">
        <div className="flex flex-col">
          <Table>
            <CustomTableHeader
              tableName="distributors"
              tableHeaders={distributorsTableHeaders}
              onHeaderCellClick={onColumnClick}
            />
            <TableBody>
              {distributors.map((user, index) => (
                <TableRow
                  key={`distributor-table-data-user-${index + 1}`}
                  className="cursor-pointer"
                  onClick={() => onDistributorClick(user.id)}
                >
                  <TableCell>{user.name}</TableCell>
                  <TableCell>{user.place}</TableCell>
                  <TableCell>{user.email}</TableCell>
                  <TableCell className="max-md:hidden">
                    {user.phoneNumber}
                  </TableCell>
                  <TableCell className="max-md:hidden">
                    {user.number_of_users}
                  </TableCell>
                  <TableCell className="max-md:hidden">
                    {formatCreationDate(new Date(user.createdAt))}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </div>
      </div>
      <PaginationSection
        page={page}
        rowsPerPage={rowsPerPage}
        filteredNumber={filteredNumberOfDistributors}
        totalNumber={totalNumberOfDistributors}
        onPageChangeClick={onPageChangeClick}
        entity="distributera"
      />
    </>
  );
};

export default DistributorsTableSection;
