import { ErrorResponse } from '@/redux/auth/auth.types.ts';

export type GetAvailableZonesResponse = {
  success: boolean;
  message?: string;
  error?: ErrorResponse;
  content?: number[];
};

export type CreateDeviceResponse = {
  success: boolean;
  message?: string;
  error?: ErrorResponse;
  content?: FetchDevice;
};

export type CreateFERTDeviceData = {
  parcelId: string;
  name: string;
  color: string;
  communicationModule: string;
  pHFactorK: number;
  pHFactorM: number;
  zonesForDevice: number[];
  supplementOne: boolean;
  supplementTwo: boolean;
  supplementThree: boolean;
  supplementFour: boolean;
  supplementFive: boolean;
  supplementMixer: boolean;
  ecSensor: boolean;
  ecFactorK: number;
  flowMeter: boolean;
  notificationForWrongFlowMeter: boolean;
  pressureSensor: boolean;
  waterLevelSensor: boolean;
};

export type FetchDevice = {
  id: string;
  name: string;
  type: string;
};

export type GetDevicesResponse = {
  success: boolean;
  message?: string;
  error?: ErrorResponse;
  content?: FetchDevice[];
};

export type CreateStartDeviceData = {
  parcelId: string;
  name: string;
  color: string;
  zonesForDevice: number[];
  humidityOne: boolean;
  humidityTwo: boolean;
  supplementMixer: boolean;
  flowMeter: boolean;
  notificationForWrongFlowMeter: boolean;
  pressureSensor: boolean;
};

export const deviceTypes = {
  FERT: 'FERT',
  SENS: 'SENS',
  START: 'START',
};

export const deviceTypeOptions = [
  {
    value: deviceTypes.FERT,
    text: 'Smart FERT',
  },
  {
    value: deviceTypes.START,
    text: 'Smart START',
  },
  {
    value: deviceTypes.SENS,
    text: 'Smart SENS',
  },
];

export type HumidityForDevice = {
  humidityOne: boolean;
  humidityTwo: boolean;
};

export type AdditionalSensorValuesStart = {
  supplementMixer: boolean;
  flowMeter: boolean;
  notificationForWrongFlowMeter: boolean;
  pressureSensor: boolean;
};

export type StartDevice = {
  id: string;
  parcelId: string;
  name: string;
  color: string;
  zonesForDevice: number[];
  humidity: HumidityForDevice;
  additionalSensorValues: AdditionalSensorValuesStart;
};

export type GetStartDeviceResponse = {
  success: boolean;
  message?: string;
  error?: ErrorResponse;
  content?: StartDevice;
};

export type SupplementForDevice = {
  supplementOne: boolean;
  supplementTwo: boolean;
  supplementThree: boolean;
  supplementFour: boolean;
  supplementFive: boolean;
};

export type AdditionalSensorValuesFERT = {
  supplementMixer: boolean;
  ecSensor: boolean;
  ecFactorK: number;
  flowMeter: boolean;
  notificationForWrongFlowMeter: boolean;
  pressureSensor: boolean;
  waterLevelSensor: boolean;
};

export type FERTDevice = {
  id: string;
  parcelId: string;
  name: string;
  color: string;
  communicationModule: string;
  pHFactorK: number;
  pHFactorM: number;
  zonesForDevice: number[];
  supplement: SupplementForDevice;
  additionalSensorsValues: AdditionalSensorValuesFERT;
};

export type GetFERTDeviceResponse = {
  success: boolean;
  message?: string;
  error?: ErrorResponse;
  content?: FERTDevice;
};

export type AdditionalSensorValuesSens = {
  flowMeter: boolean;
  soilTemperature: boolean;
  soilSalinity: boolean;
  soilPH: boolean;
  airTemperature: boolean;
  airHumidity: boolean;
  windDirection: boolean;
  windSpeed: boolean;
  solarRadiation: boolean;
  amountOfPrecipitation: boolean;
  batteryVoltage: boolean;
  notificationForWrongFlowMeter: boolean;
  pressureSensor: boolean;
};

export type CreateSensDevice = {
  parcelId: string;
  name: string;
  color: string;
  zonesForDevice: number[];
  standbyModeTime: number;
  sensSensorType: number;
  humidityOne: boolean;
  humidityTwo: boolean;
  flowMeter: boolean;
  soilTemperature: boolean;
  soilSalinity: boolean;
  soilPH: boolean;
  airTemperature: boolean;
  airHumidity: boolean;
  windDirection: boolean;
  windSpeed: boolean;
  solarRadiation: boolean;
  amountOfPrecipitation: boolean;
  batteryVoltage: boolean;
  notificationForWrongFlowMeter: boolean;
  pressureSensor: boolean;
};

export type SensDevice = {
  id: string;
  parcelId: string;
  name: string;
  color: string;
  zonesForDevice: number[];
  standbyModeTime: number;
  sensSensorType: number;
  humidity: HumidityForDevice;
  additionalSensorsValues: AdditionalSensorValuesSens;
};

export type GetSensDeviceResponse = {
  success: boolean;
  message?: string;
  error?: ErrorResponse;
  content?: SensDevice;
};
