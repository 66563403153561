import { zodResolver } from '@hookform/resolvers/zod';
import { FC } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import CustomFormInput from '@/components/controls/CustomFormInput.tsx';
import { Button } from '@/components/ui/button.tsx';
import { Form } from '@/components/ui/form.tsx';
import { logoutState, setName } from '@/redux/auth/auth.slice.ts';
import { createFruitType } from '@/redux/fruitType/fruitType.actions.ts';
import { CreateFruitTypeResponse } from '@/redux/fruitType/fruitType.types.ts';
import { useAppDispatch } from '@/redux/hooks.ts';
import { createFruitTypeSchema } from '@/zod/types.ts';

export type CreateFruitTypeFormData = {
  name: string;
};

const AddFruitTypeForm: FC = () => {
  const form = useForm({
    resolver: zodResolver(createFruitTypeSchema),
    defaultValues: {
      name: '',
    },
  });
  const dispatch = useAppDispatch();
  const router = useNavigate();

  const handleErrorResponse = (response: CreateFruitTypeResponse) => {
    if (response.error.removeUser) {
      localStorage.removeItem('token');
      localStorage.removeItem('name');
      dispatch(logoutState());
      dispatch(setName(''));
      router('/login');
      return;
    }

    toast.error(response.error.message);
  };

  const onSubmit = async (data: CreateFruitTypeFormData) => {
    // @ts-ignore
    const response = await dispatch(createFruitType(data)).unwrap();
    if (!response.success) {
      handleErrorResponse(response);
      return;
    }

    form.reset();
  };

  return (
    <Form
      reset={form.reset}
      formState={form.formState}
      clearErrors={form.clearErrors}
      control={form.control}
      getFieldState={form.getFieldState}
      getValues={form.getValues}
      handleSubmit={form.handleSubmit}
      register={form.register}
      resetField={form.resetField}
      setError={form.setError}
      setFocus={form.setFocus}
      setValue={form.setValue}
      trigger={form.trigger}
      unregister={form.unregister}
      watch={form.watch}
    >
      <form
        className="flex items-start flex-col mt-5 max-md:mt-2 pb-10 max-md:pb-5"
        onSubmit={form.handleSubmit(onSubmit)}
      >
        <CustomFormInput
          customForm={form}
          name="name"
          formItemStyle="flex flex-col max-md:flex-col w-full pb-5 max-md:py-5 space-y-0"
          divStyle="w-full"
          type="text"
          placeholder="Ime Vrste"
          inputStyle="py-3 px-2"
          labelText=""
        />
        <Button className="bg-[#007bff] py-3 px-2 text-white" type="submit">
          Dodaj vrstu
        </Button>
      </form>
    </Form>
  );
};

export default AddFruitTypeForm;
