import { FC, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import EditFERTDeviceForm, {
  EditFERTDeviceFormData,
} from '@/components/forms/EditFERTDeviceForm.tsx';
import EditSensDeviceForm, {
  EditSensDeviceFormData,
} from '@/components/forms/EditSensDeviceForm.tsx';
import EditStartDeviceForm, {
  EditStartDeviceFormData,
} from '@/components/forms/EditStartDeviceForm.tsx';
import { logoutState, setName } from '@/redux/auth/auth.slice.ts';
import {
  getAvailableZonesForParcel,
  getFERTDeviceById,
  getSensDeviceById,
  getStartDeviceById,
} from '@/redux/device/devices.actions.ts';
import {
  deviceTypes,
  GetAvailableZonesResponse,
  GetFERTDeviceResponse,
  GetSensDeviceResponse,
  GetStartDeviceResponse,
} from '@/redux/device/devices.types.ts';
import { useAppDispatch } from '@/redux/hooks.ts';
import {
  transformFERTDeviceForForm,
  transformSensDeviceForForm,
  transformStartDeviceForForm,
} from '@/utils/device.util.ts';

const EditDeviceContent: FC = () => {
  const params = useParams();
  const router = useNavigate();
  const dispatch = useAppDispatch();
  const [transformedStartDevice, setTransformedStartDevice] =
    useState<EditStartDeviceFormData>();
  const [transformedFERTDevice, setTransformedFERTDevice] =
    useState<EditFERTDeviceFormData>();
  const [transformedSensDevice, setTransformedSensDevice] =
    useState<EditSensDeviceFormData>();

  const handleErrorResponse = (
    response:
      | GetStartDeviceResponse
      | GetFERTDeviceResponse
      | GetAvailableZonesResponse
      | GetSensDeviceResponse
  ) => {
    if (response.error.removeUser) {
      localStorage.removeItem('token');
      localStorage.removeItem('name');
      dispatch(logoutState());
      dispatch(setName(''));
      router('/login');
      return;
    }

    toast.error(response.error.message);
  };

  const fetchFERTDevice = async () => {
    // @ts-ignore
    const response = await dispatch(
      getFERTDeviceById(params.deviceId)
    ).unwrap();

    if (!response.success) {
      handleErrorResponse(response);
      return;
    }

    setTransformedFERTDevice(transformFERTDeviceForForm(response.content));
  };

  const fetchStartDevice = async () => {
    // @ts-ignore
    const response = await dispatch(
      getStartDeviceById(params.deviceId)
    ).unwrap();

    if (!response.success) {
      handleErrorResponse(response);
      return;
    }

    setTransformedStartDevice(transformStartDeviceForForm(response.content));
  };

  const fetchSensDevice = async () => {
    // @ts-ignore
    const response = await dispatch(
      getSensDeviceById(params.deviceId)
    ).unwrap();

    if (!response.success) {
      handleErrorResponse(response);
      return;
    }

    setTransformedSensDevice(transformSensDeviceForForm(response.content));
  };

  useEffect(() => {
    if (params.deviceType === deviceTypes.START) {
      fetchStartDevice();
      return;
    }

    if (params.deviceType === deviceTypes.SENS) {
      fetchSensDevice();
      return;
    }

    fetchFERTDevice();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const fetchAvailableZones = async () => {
      // @ts-ignore
      const response = await dispatch(
        getAvailableZonesForParcel(params.parcelId)
      ).unwrap();
      if (!response.success) {
        handleErrorResponse(response);
      }
    };

    fetchAvailableZones();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="flex w-[88%] flex-col bg-[#85b4f2] bg-opacity-10 max-md:w-full">
      <nav className="flex itemst-start bg-white border-b-2 w-full px-10 py-5 max-md:px-5">
        <div className="text-3xl font-bold">Izmeni uredjaj</div>
      </nav>
      <div className="flex items-start gap-6 p-10 max-md:p-5">
        <div className="bg-white w-full shadow-lg">
          <nav className="flex items-center justify-between bg-white border-b-2 w-full px-5 py-3">
            <div className="text-xl font-bold text-center">
              Detalji uredjaja
            </div>
          </nav>
          {params.deviceType === deviceTypes.FERT && (
            <EditFERTDeviceForm transformedFERTDevice={transformedFERTDevice} />
          )}
          {params.deviceType === deviceTypes.START && (
            <EditStartDeviceForm
              transformedStartDevice={transformedStartDevice}
            />
          )}
          {params.deviceType === deviceTypes.SENS && (
            <EditSensDeviceForm transformedSensDevice={transformedSensDevice} />
          )}
        </div>
      </div>
    </div>
  );
};

export default EditDeviceContent;
