import { FC } from 'react';
import { UseFormReturn } from 'react-hook-form';

import CustomFormCheckbox from '@/components/controls/CustomFormCheckbox.tsx';
import CustomFormInput from '@/components/controls/CustomFormInput.tsx';

type BillingAddressFormProps = {
  form: UseFormReturn<any>;
  isFormShown: boolean;
};

const BillingAddressForm: FC<BillingAddressFormProps> = ({
  form,
  isFormShown,
}) => {
  return (
    <div className="border-b-2 border-dotted py-10">
      <CustomFormCheckbox
        form={form}
        name="differentBillingAddress"
        labelText="Da li se adresa za dostavu računa razlikuje?"
        formItemStyle="flex flex-col gap-2"
        checkboxStyle="w-5 h-5 data-[state=checked]:bg-[#007bff] data-[state=checked]:text-neutral-50"
      />
      {isFormShown && (
        <>
          <CustomFormInput
            customForm={form}
            name="billingAddress"
            labelText="Adresa dostave"
            formItemStyle="flex flex-row max-md:flex-col w-full border-b-2 border-dotted pb-10 py-10 max-md:py-5"
            labelStyle="max-md:w-full w-[15%]"
            divStyle="max-md:w-full w-[85%]"
            type="text"
          />
          <CustomFormInput
            customForm={form}
            name="billingPlace"
            labelText="Mesto dostave"
            formItemStyle="flex flex-row max-md:flex-col w-full border-b-2 border-dotted pb-10 py-10 max-md:py-5"
            labelStyle="max-md:w-full w-[15%]"
            divStyle="max-md:w-full w-[85%]"
            type="text"
          />
          <CustomFormInput
            customForm={form}
            name="billingCountry"
            labelText="Drzava dostave"
            formItemStyle="flex flex-row max-md:flex-col w-full pt-10 max-md:pt-5"
            labelStyle="max-md:w-full w-[15%]"
            divStyle="max-md:w-full w-[85%]"
            type="text"
          />
        </>
      )}
    </div>
  );
};

export default BillingAddressForm;
