import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { FetchFruitSort } from '@/redux/fruitSort/fruitSort.types.ts';

type FruitSortInitialState = {
  fruitSorts: FetchFruitSort[];
};

const fruitSortsInitialState: FruitSortInitialState = {
  fruitSorts: [],
};

const fruitSortsSlice = createSlice({
  name: 'fruitSortsSlice',
  initialState: fruitSortsInitialState,
  reducers: {
    addFruitSort: (state, action: PayloadAction<FetchFruitSort>) => {
      state.fruitSorts = [...state.fruitSorts, action.payload];
    },
    setFruitSorts: (state, action: PayloadAction<FetchFruitSort[]>) => {
      state.fruitSorts = action.payload || [];
    },
    removeFruitSortsForRemovedType: (state, action: PayloadAction<string>) => {
      state.fruitSorts = state.fruitSorts.filter(
        (fruitSort) => fruitSort.fruitTypeId !== action.payload
      );
    },
    removeFruitSort: (state, action: PayloadAction<string>) => {
      state.fruitSorts = state.fruitSorts.filter(
        (fruitSort) => fruitSort.id !== action.payload
      );
    },
  },
});

export const {
  addFruitSort,
  setFruitSorts,
  removeFruitSortsForRemovedType,
  removeFruitSort,
} = fruitSortsSlice.actions;
export default fruitSortsSlice.reducer;
