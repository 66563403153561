import { zodResolver } from '@hookform/resolvers/zod';
import { FC } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import CustomFormDropDown from '@/components/controls/CustomFormDropDown.tsx';
import CustomFormInput from '@/components/controls/CustomFormInput.tsx';
import CustomFormMultiSelect from '@/components/controls/CustomFormMultiSelect.tsx';
import FERTDeviceSensorsForm from '@/components/forms/devices/FERTDeviceSensorsForm.tsx';
import FERTDeviceSupplementForm from '@/components/forms/devices/FERTDeviceSupplementForm.tsx';
import { Button } from '@/components/ui/button.tsx';
import { Form } from '@/components/ui/form.tsx';
import { logoutState, setName } from '@/redux/auth/auth.slice.ts';
import { createFERTDevice } from '@/redux/device/devices.actions.ts';
import { getAvailableZones } from '@/redux/device/devices.selectors.ts';
import { CreateDeviceResponse } from '@/redux/device/devices.types.ts';
import { useAppDispatch, useAppSelector } from '@/redux/hooks.ts';
import { communicationModules } from '@/utils/device.util.ts';
import { createFERTDeviceSchema } from '@/zod/types.ts';

export type CreateFERTDeviceFormData = {
  parcelId: string;
  name: string;
  color: string;
  communicationModule: string;
  pHFactorK: string;
  pHFactorM: string;
  zonesForDevice: string[];
  supplementOne: string;
  supplementTwo: string;
  supplementThree: string;
  supplementFour: string;
  supplementFive: string;
  supplementMixer: string;
  ecSensor: string;
  ecFactorK: string;
  flowMeter: string;
  notificationForWrongFlowMeter: string;
  pressureSensor: string;
  waterLevelSensor: string;
};

const CreateFERTDeviceForm: FC = () => {
  const params = useParams();
  const form = useForm({
    resolver: zodResolver(createFERTDeviceSchema),
    defaultValues: {
      parcelId: params.parcelId as string,
      name: '',
      color: '#4613ad',
      communicationModule: 'LTE',
      pHFactorK: '1',
      pHFactorM: '0',
      zonesForDevice: [],
      supplementOne: 'true',
      supplementTwo: 'true',
      supplementThree: 'true',
      supplementFour: 'false',
      supplementFive: 'false',
      supplementMixer: 'false',
      ecSensor: 'true',
      ecFactorK: '1',
      flowMeter: 'true',
      notificationForWrongFlowMeter: 'true',
      pressureSensor: 'false',
      waterLevelSensor: 'false',
    },
  });
  const dispatch = useAppDispatch();
  const router = useNavigate();
  const availableZones = useAppSelector(getAvailableZones);
  const multiSelectData = availableZones.map((zone) => {
    return {
      label: `Zona ${zone}`,
      value: zone.toString(),
    };
  });

  const handleErrorResponse = (response: CreateDeviceResponse) => {
    if (response.error.removeUser) {
      localStorage.removeItem('token');
      localStorage.removeItem('name');
      dispatch(logoutState());
      dispatch(setName(''));
      router('/login');
      return;
    }

    toast.error(response.error.message);
  };

  const onGiveUpClick = () => {
    router(`/devices/${params.parcelId}`);
  };

  const onSubmit = async (data: CreateFERTDeviceFormData) => {
    // @ts-ignore
    const response = await dispatch(createFERTDevice(data)).unwrap();

    if (!response.success) {
      handleErrorResponse(response);
      return;
    }

    toast.success('Uspešno dodat uredjaj');
    router(`/devices/${params.parcelId}`);
  };

  return (
    <Form
      reset={form.reset}
      formState={form.formState}
      clearErrors={form.clearErrors}
      control={form.control}
      getFieldState={form.getFieldState}
      getValues={form.getValues}
      handleSubmit={form.handleSubmit}
      register={form.register}
      resetField={form.resetField}
      setError={form.setError}
      setFocus={form.setFocus}
      setValue={form.setValue}
      trigger={form.trigger}
      unregister={form.unregister}
      watch={form.watch}
    >
      <form onSubmit={form.handleSubmit(onSubmit)}>
        <div className="border-b border-gray-900/10 pt-5 px-5 text-sm">
          <div className=" flex flex-col">
            <CustomFormInput
              customForm={form}
              name="name"
              labelText="Ime uredjaja"
              formItemStyle="flex flex-row max-md:flex-col w-full border-b-2 border-dotted pb-10 max-md:py-5"
              labelStyle="max-md:w-full w-[15%]"
              divStyle="max-md:w-full w-[85%]"
              type="text"
            />
            <CustomFormInput
              customForm={form}
              name="color"
              labelText="Boja uredjaja"
              formItemStyle="flex flex-col w-full border-b-2 border-dotted pb-10 py-10 max-md:py-5"
              labelStyle="max-md:w-full"
              divStyle="w-[50px]"
              type="color"
            />
            <CustomFormDropDown
              customForm={form}
              name="communicationModule"
              labelText="Komunikacioni modul"
              formItemStyle="flex w-full flex-col max-md:mt-2 max-md:w-full pb-10 py-10 border-b-2 border-dotted"
              items={communicationModules}
              placeholder="Odaberite korisnika"
            />
            <CustomFormMultiSelect
              customForm={form}
              name="zonesForDevice"
              labelText="Zone za uredjaj"
              items={multiSelectData}
              formItemStyle="flex w-full flex-col max-md:mt-2 max-md:w-full pb-10 py-10 border-b-2 border-dotted"
              placeholder="Izaberite zonu/e"
            />
            <CustomFormInput
              customForm={form}
              name="pHFactorK"
              labelText="pH faktor K"
              formItemStyle="flex flex-row max-md:flex-col w-full border-b-2 border-dotted pb-10 py-10 max-md:py-5"
              labelStyle="max-md:w-full w-[15%]"
              divStyle="max-md:w-full w-[85%]"
              type="number"
            />
            <CustomFormInput
              customForm={form}
              name="pHFactorM"
              labelText="pH faktor M"
              formItemStyle="flex flex-row max-md:flex-col w-full border-b-2 border-dotted pb-10 py-10 max-md:py-5"
              labelStyle="max-md:w-full w-[15%]"
              divStyle="max-md:w-full w-[85%]"
              type="number"
            />
            <CustomFormInput
              customForm={form}
              name="ecFactorK"
              labelText="Kalibracioni koeficijent K za EC"
              formItemStyle="flex flex-row max-md:flex-col w-full border-b-2 border-dotted pb-10 py-10 max-md:py-5"
              labelStyle="max-md:w-full w-[15%]"
              divStyle="max-md:w-full w-[85%]"
              type="number"
            />
            <FERTDeviceSupplementForm form={form} />
            <FERTDeviceSensorsForm form={form} />
            <div className="flex flex-row max-md:flex-col w-full py-10">
              <div className="w-[85%] max-md:w-full flex flex-row gap-5">
                <Button
                  className="bg-gray-600 text-white py-3 px-2"
                  onClick={onGiveUpClick}
                >
                  Odustani
                </Button>
                <Button
                  className="bg-[#007bff] py-3 px-2 text-white"
                  type="submit"
                >
                  Dodaj uredjaj
                </Button>
              </div>
            </div>
          </div>
        </div>
      </form>
    </Form>
  );
};

export default CreateFERTDeviceForm;
