import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { FetchParcel } from '@/redux/parcels/parcels.types.ts';

type ParcelInitialState = {
  parcels: FetchParcel[];
  totalNumberOfParcels: number;
  filteredNumberOfParcels: number;
};

const parcelsInitialState: ParcelInitialState = {
  parcels: [],
  totalNumberOfParcels: 0,
  filteredNumberOfParcels: 0,
};

const parcelsSlice = createSlice({
  name: 'parcelsSlice',
  initialState: parcelsInitialState,
  reducers: {
    addParcel: (state, action) => {
      state.parcels = [...state.parcels, action.payload];
    },
    setParcels: (state, action: PayloadAction<FetchParcel[]>) => {
      state.parcels = action.payload || [];
    },
    setTotalNumberOfParcels: (state, action: PayloadAction<number>) => {
      state.totalNumberOfParcels = action.payload;
    },
    setFilteredNumberOfParcels: (state, action: PayloadAction<number>) => {
      state.filteredNumberOfParcels = action.payload;
    },
  },
});

export const {
  addParcel,
  setParcels,
  setTotalNumberOfParcels,
  setFilteredNumberOfParcels,
} = parcelsSlice.actions;
export default parcelsSlice.reducer;
