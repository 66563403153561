import { format } from 'date-fns';
import { Calendar as CalendarIcon } from 'lucide-react';
import { FC } from 'react';
import { UseFormReturn } from 'react-hook-form';

import { Button } from '@/components/ui/button.tsx';
import { Calendar } from '@/components/ui/calendar.tsx';
import {
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@/components/ui/form.tsx';
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from '@/components/ui/popover.tsx';
import { cn } from '@/lib/utils.ts';

type CustomFormDatePickerProps = {
  customForm: UseFormReturn<any, any, any>;
  name: string;
  labelText: string;
  formItemStyle?: string;
  labelStyle?: string;
  divStyle?: string;
  buttonStyle?: string;
};

const CustomFormDatePicker: FC<CustomFormDatePickerProps> = ({
  customForm,
  name,
  labelText,
  formItemStyle,
  divStyle,
  labelStyle,
  buttonStyle,
}) => {
  return (
    <FormField
      control={customForm.control}
      name={name}
      render={({ field }) => (
        <FormItem className={formItemStyle}>
          <FormLabel className={labelStyle}>{labelText}</FormLabel>
          <div className={divStyle}>
            <Popover>
              <PopoverTrigger asChild>
                <FormControl>
                  <Button variant="outline" className={cn(buttonStyle)}>
                    {field.value ? (
                      format(field.value, 'PPP')
                    ) : (
                      <span>Pick a date</span>
                    )}
                    <CalendarIcon className="ml-auto h-4 w-4 opacity-50" />
                  </Button>
                </FormControl>
              </PopoverTrigger>
              <PopoverContent className="w-auto p-0" align="center">
                <Calendar
                  mode="single"
                  selected={field.value}
                  onSelect={field.onChange}
                />
              </PopoverContent>
            </Popover>
            <FormMessage className="mt-2" />
          </div>
        </FormItem>
      )}
    />
  );
};

export default CustomFormDatePicker;
