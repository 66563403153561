import { createAsyncThunk } from '@reduxjs/toolkit';
import * as Sentry from '@sentry/react';

import { AddParcelFormData } from '@/components/forms/AddParcelForm.tsx';
import { EditParcelFormData } from '@/components/forms/EditParcelForm.tsx';
import { CreateDistributorResponse } from '@/redux/distributors/distributors.types.ts';
import {
  setFilteredNumberOfParcels,
  setParcels,
  setTotalNumberOfParcels,
} from '@/redux/parcels/parcels.slice.ts';
import {
  CreateParcelResponse,
  GetParcelsResponse,
} from '@/redux/parcels/parcels.types.ts';
import axiosServer from '@/services/axios.service.ts';

const createParcel = createAsyncThunk<CreateParcelResponse, AddParcelFormData>(
  'admin/create-parcel',
  async (reqData) => {
    try {
      const axiosResponse = await axiosServer.post<CreateDistributorResponse>(
        '/admin/parcel/create-parcel',
        reqData
      );
      if (!axiosResponse.data.success) {
        return axiosResponse.data;
      }

      return axiosResponse.data;
    } catch (error) {
      Sentry.captureException(error);
      return error;
    }
  }
);

const getFilteredParcels = createAsyncThunk<GetParcelsResponse, string>(
  'admin/get-parcels',
  async (reqData, { dispatch }) => {
    try {
      const axiosResponse = await axiosServer.get<GetParcelsResponse>(
        `/admin/parcel${reqData}`
      );
      if (!axiosResponse.data.success) {
        return axiosResponse.data;
      }

      dispatch(setParcels(axiosResponse.data.content.fetchedParcels));
      dispatch(setTotalNumberOfParcels(axiosResponse.data.content.totalCount));
      dispatch(
        setFilteredNumberOfParcels(axiosResponse.data.content.filteredCount)
      );
      return axiosResponse.data;
    } catch (error) {
      Sentry.captureException(error);
      return error;
    }
  }
);

const getParcelById = createAsyncThunk<CreateParcelResponse, string>(
  'admin/get-parcel-by-id',
  async (reqData) => {
    try {
      const axiosResponse = await axiosServer.get<CreateParcelResponse>(
        `/admin/parcel/get-one?id=${reqData}`
      );
      if (!axiosResponse.data.success) {
        return axiosResponse.data;
      }

      return axiosResponse.data;
    } catch (error) {
      Sentry.captureException(error);
      return error;
    }
  }
);

const updateParcel = createAsyncThunk<CreateParcelResponse, EditParcelFormData>(
  'admin/update-parcel',
  async (reqData) => {
    try {
      const axiosResponse = await axiosServer.put<CreateParcelResponse>(
        '/admin/parcel/update',
        reqData
      );
      if (!axiosResponse.data.success) {
        return axiosResponse.data;
      }

      return axiosResponse.data;
    } catch (error) {
      Sentry.captureException(error);
      return error;
    }
  }
);

const deleteParcelById = createAsyncThunk<CreateParcelResponse, string>(
  'admin/delete-parcel',
  async (reqData) => {
    try {
      const axiosResponse = await axiosServer.delete<CreateParcelResponse>(
        `/admin/parcel/delete-parcel?id=${reqData}`
      );
      if (!axiosResponse.data.success) {
        return axiosResponse.data;
      }

      return axiosResponse.data;
    } catch (error) {
      Sentry.captureException(error);
      return error;
    }
  }
);

export {
  createParcel,
  getFilteredParcels,
  getParcelById,
  updateParcel,
  deleteParcelById,
};
