import { FC, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import FruitSortContent from '@/components/layout/fruits/FruitSortContent.tsx';
import FruitTypesContent from '@/components/layout/fruits/FruitTypesContent.tsx';
import MobileFooterNavigation from '@/components/layout/MobileFooterNavigation.tsx';
import Navbar from '@/components/layout/Navbar.tsx';
import Sidebar from '@/components/layout/Sidebar.tsx';
import { logoutState, setName } from '@/redux/auth/auth.slice.ts';
import { GetFruitSortsResponse } from '@/redux/fruitSort/fruitSort.types.ts';
import { getAllFruitTypes } from '@/redux/fruitType/fruitType.actions.ts';
import { GetFruitTypesResponse } from '@/redux/fruitType/fruitType.types.ts';
import { useAppDispatch } from '@/redux/hooks.ts';

const FruitsPage: FC = () => {
  const dispatch = useAppDispatch();
  const router = useNavigate();

  const handleErrorResponse = (
    response: GetFruitTypesResponse | GetFruitSortsResponse
  ) => {
    if (response.error.removeUser) {
      localStorage.removeItem('token');
      localStorage.removeItem('name');
      dispatch(logoutState());
      dispatch(setName(''));
      router('/login');
      return;
    }

    toast.error(response.error.message);
  };

  useEffect(() => {
    const fetchFruitTypes = async () => {
      // @ts-ignore
      const response = await dispatch(getAllFruitTypes()).unwrap();

      if (!response.success) {
        handleErrorResponse(response);
      }
    };

    fetchFruitTypes();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="flex h-screen w-full flex-col">
      <Navbar />
      <div className="flex flex-1 flex-row">
        <Sidebar />
        <div className="flex w-[88%] flex-col bg-[#85b4f2] bg-opacity-10 max-md:w-full">
          <nav className="flex itemst-start bg-white border-b-2 w-full px-10 py-5 max-md:px-5">
            <h1 className="text-3xl font-bold">Vrste</h1>
          </nav>
          <FruitTypesContent />
          <FruitSortContent />
        </div>
      </div>
      <MobileFooterNavigation />
    </div>
  );
};

export default FruitsPage;
