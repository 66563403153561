import { zodResolver } from '@hookform/resolvers/zod';
import { FC, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import CustomFormInput from '@/components/controls/CustomFormInput.tsx';
import CustomFormMultiSelect, {
  MultiSelectItem,
} from '@/components/controls/CustomFormMultiSelect.tsx';
import DeviceFormButtonSection from '@/components/forms/devices/DeviceFormButtonSection.tsx';
import StartDeviceHumidityForm from '@/components/forms/devices/StartDeviceHumidityForm.tsx';
import StartDeviceSensorsForm from '@/components/forms/devices/StartDeviceSensorsForm.tsx';
import ConfirmDeleteDialog from '@/components/layout/ConfirmDeleteDialog.tsx';
import { Form } from '@/components/ui/form.tsx';
import { logoutState, setName } from '@/redux/auth/auth.slice.ts';
import {
  deleteStartDeviceById,
  updateStartDevice,
} from '@/redux/device/devices.actions.ts';
import {
  getAvailableZones,
  getStartDeviceForEdit,
} from '@/redux/device/devices.selectors.ts';
import { CreateDeviceResponse } from '@/redux/device/devices.types.ts';
import { useAppDispatch, useAppSelector } from '@/redux/hooks.ts';
import { formAvailableZonesContent } from '@/utils/device.util.ts';
import { editStartDeviceSchema } from '@/zod/types.ts';

export type EditStartDeviceFormData = {
  id: string;
  parcelId: string;
  name: string;
  color: string;
  zonesForDevice: string[];
  humidityOne: string;
  humidityTwo: string;
  supplementMixer: string;
  flowMeter: string;
  notificationForWrongFlowMeter: string;
  pressureSensor: string;
};

type EditStartDeviceFormProps = {
  transformedStartDevice: EditStartDeviceFormData;
};

const EditStartDeviceForm: FC<EditStartDeviceFormProps> = ({
  transformedStartDevice,
}) => {
  const startDevice = useAppSelector(getStartDeviceForEdit);
  const params = useParams();
  const form = useForm({
    resolver: zodResolver(editStartDeviceSchema),
    defaultValues: {
      id: params.deviceId,
      parcelId: params.parcelId,
      name: '',
      color: '#4613ad',
      zonesForDevice: [],
      humidityOne: 'false',
      humidityTwo: 'false',
      supplementMixer: 'true',
      flowMeter: 'true',
      notificationForWrongFlowMeter: 'true',
      pressureSensor: 'false',
    },
  });
  const dispatch = useAppDispatch();
  const router = useNavigate();
  const availableZones = useAppSelector(getAvailableZones);
  const [multiSelectData, setMultiSelectData] = useState<MultiSelectItem[]>([]);
  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);

  const handleErrorResponse = (response: CreateDeviceResponse) => {
    if (response.error.removeUser) {
      localStorage.removeItem('token');
      localStorage.removeItem('name');
      dispatch(logoutState());
      dispatch(setName(''));
      router('/login');
      return;
    }

    toast.error(response.error.message);
  };

  const onGiveUpClick = () => {
    router(`/devices/${params.parcelId}`);
  };

  const onSubmit = async (data: EditStartDeviceFormData) => {
    // @ts-ignore
    const response = await dispatch(updateStartDevice(data)).unwrap();

    if (!response.success) {
      handleErrorResponse(response);
      return;
    }

    toast.success('Uspešno ažuriran uredjaj');
    router(`/devices/${params.parcelId}`);
  };

  const onDeleteClick = () => {
    setIsDialogOpen(true);
  };

  const onDeleteDeviceClick = async () => {
    // @ts-ignore
    const response = await dispatch(
      deleteStartDeviceById(params.deviceId)
    ).unwrap();
    if (!response.success) {
      handleErrorResponse(response);
      return;
    }

    toast.success('Uredjaj je uspešno izbrisan');
    router(`/devices/${params.parcelId}`);
  };

  useEffect(() => {
    if (transformedStartDevice) {
      form.reset({
        id: params.deviceId,
        parcelId: params.parcelId,
        name: transformedStartDevice.name,
        color: transformedStartDevice.color,
        zonesForDevice: transformedStartDevice.zonesForDevice,
        humidityOne: transformedStartDevice.humidityOne,
        humidityTwo: transformedStartDevice.humidityTwo,
        supplementMixer: transformedStartDevice.supplementMixer,
        flowMeter: transformedStartDevice.flowMeter,
        notificationForWrongFlowMeter:
          transformedStartDevice.notificationForWrongFlowMeter,
        pressureSensor: transformedStartDevice.pressureSensor,
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [transformedStartDevice]);

  useEffect(() => {
    setMultiSelectData(formAvailableZonesContent(startDevice, availableZones));
  }, [startDevice, availableZones]);

  return (
    <>
      <Form
        reset={form.reset}
        formState={form.formState}
        clearErrors={form.clearErrors}
        control={form.control}
        getFieldState={form.getFieldState}
        getValues={form.getValues}
        handleSubmit={form.handleSubmit}
        register={form.register}
        resetField={form.resetField}
        setError={form.setError}
        setFocus={form.setFocus}
        setValue={form.setValue}
        trigger={form.trigger}
        unregister={form.unregister}
        watch={form.watch}
      >
        <form onSubmit={form.handleSubmit(onSubmit)}>
          <div className="border-b border-gray-900/10 pt-5 px-5 text-sm">
            <div className=" flex flex-col">
              <CustomFormInput
                customForm={form}
                name="name"
                labelText="Ime uredjaja"
                formItemStyle="flex flex-row max-md:flex-col w-full border-b-2 border-dotted pb-10 max-md:py-5"
                labelStyle="max-md:w-full w-[15%]"
                divStyle="max-md:w-full w-[85%]"
                type="text"
              />
              <CustomFormInput
                customForm={form}
                name="color"
                labelText="Boja uredjaja"
                formItemStyle="flex flex-col w-full border-b-2 border-dotted pb-10 py-10 max-md:py-5"
                labelStyle="max-md:w-full"
                divStyle="w-[50px]"
                type="color"
              />
              <CustomFormMultiSelect
                customForm={form}
                name="zonesForDevice"
                labelText="Zone za uredjaj"
                items={multiSelectData}
                formItemStyle="flex w-full flex-col max-md:mt-2 max-md:w-full pb-10 py-10 border-b-2 border-dotted"
                placeholder="Izaberite zonu/e"
              />
              <StartDeviceHumidityForm form={form} />
              <StartDeviceSensorsForm form={form} />
              <DeviceFormButtonSection
                onGiveUpClick={onGiveUpClick}
                onDeleteDeviceClick={onDeleteClick}
              />
            </div>
          </div>
        </form>
      </Form>
      <ConfirmDeleteDialog
        headerText="Da li ste sigurni da želite da obrišete uredjaj?"
        descriptionText="Ova akcija se ne može poništiti. Trajno ćete obrisati uredjaj."
        onContinueClick={onDeleteDeviceClick}
        isDialogOpen={isDialogOpen}
        setIsDialogOpen={setIsDialogOpen}
      />
    </>
  );
};

export default EditStartDeviceForm;
