import { FC } from 'react';

import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
} from '@/components/ui/alert-dialog.tsx';

type ConfirmDeleteDialogProps = {
  headerText: string;
  descriptionText: string;
  onContinueClick: () => void;
  isDialogOpen: boolean;
  setIsDialogOpen: (value: ((prevState: boolean) => boolean) | boolean) => void;
};

const ConfirmDeleteDialog: FC<ConfirmDeleteDialogProps> = ({
  headerText,
  descriptionText,
  onContinueClick,
  isDialogOpen,
  setIsDialogOpen,
}) => {
  return (
    <AlertDialog
      open={isDialogOpen}
      onOpenChange={() => setIsDialogOpen(false)}
    >
      <AlertDialogContent>
        <AlertDialogHeader>
          <AlertDialogTitle>{headerText}</AlertDialogTitle>
          <AlertDialogDescription>{descriptionText}</AlertDialogDescription>
        </AlertDialogHeader>
        <AlertDialogFooter>
          <AlertDialogCancel className="bg-white py-3 px-5 text-black">
            Otkaži
          </AlertDialogCancel>
          <AlertDialogAction
            onClick={onContinueClick}
            className="bg-[#dc3545] py-3 px-5 text-white"
          >
            Obriši
          </AlertDialogAction>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
};

export default ConfirmDeleteDialog;
