import { FC, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import CreateFERTDeviceForm from '@/components/forms/CreateFERTDeviceForm.tsx';
import CreateSensDeviceForm from '@/components/forms/CreateSensDeviceForm.tsx';
import CreateStartDeviceForm from '@/components/forms/CreateStartDeviceForm.tsx';
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/components/ui/select.tsx';
import { logoutState, setName } from '@/redux/auth/auth.slice.ts';
import { getAvailableZonesForParcel } from '@/redux/device/devices.actions.ts';
import {
  deviceTypeOptions,
  deviceTypes,
  GetAvailableZonesResponse,
} from '@/redux/device/devices.types.ts';
import { useAppDispatch } from '@/redux/hooks.ts';

type SelectDeviceTypeSectionProps = {
  onDeviceTypeChange: (value: string) => void;
};

const SelectDeviceTypeSection: FC<SelectDeviceTypeSectionProps> = ({
  onDeviceTypeChange,
}) => {
  return (
    <Select onValueChange={onDeviceTypeChange}>
      <SelectTrigger className="w-[180px]">
        <SelectValue placeholder="Tip uredjaja" />
      </SelectTrigger>
      <SelectContent>
        <SelectGroup>
          {deviceTypeOptions.map((deviceType, idx) => (
            <SelectItem
              key={`device-type-${deviceType.value}-${idx + 1}`}
              value={deviceType.value}
            >
              {deviceType.text}
            </SelectItem>
          ))}
        </SelectGroup>
      </SelectContent>
    </Select>
  );
};

const CreateDeviceContent: FC = () => {
  const params = useParams();
  const dispatch = useAppDispatch();
  const router = useNavigate();
  const [selectedDeviceType, setSelectedDeviceType] = useState<string>('');

  const onDeviceTypeChange = (value: string) => {
    setSelectedDeviceType(value);
  };

  const handleErrorResponse = (response: GetAvailableZonesResponse) => {
    if (response.error.removeUser) {
      localStorage.removeItem('token');
      localStorage.removeItem('name');
      dispatch(logoutState());
      dispatch(setName(''));
      router('/login');
      return;
    }

    toast.error(response.error.message);
  };

  useEffect(() => {
    const fetchAvailableZones = async () => {
      // @ts-ignore
      const response = await dispatch(
        getAvailableZonesForParcel(params.parcelId)
      ).unwrap();
      if (!response.success) {
        handleErrorResponse(response);
      }
    };

    fetchAvailableZones();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="flex w-[88%] flex-col bg-[#85b4f2] bg-opacity-10 max-md:w-full">
      <nav className="flex itemst-start bg-white border-b-2 w-full px-10 py-5 max-md:px-5">
        <div className="text-3xl font-bold">Dodaj novi uredjaj</div>
      </nav>
      <div className="flex items-start gap-6 p-10 max-md:p-5">
        <div className="bg-white w-full shadow-lg">
          <nav className="flex items-center justify-between bg-white border-b-2 w-full px-5 py-3">
            <div className="text-xl font-bold text-center">Novi uredjaj</div>
            <SelectDeviceTypeSection onDeviceTypeChange={onDeviceTypeChange} />
          </nav>
          {selectedDeviceType === deviceTypes.FERT && <CreateFERTDeviceForm />}
          {selectedDeviceType === deviceTypes.START && (
            <CreateStartDeviceForm />
          )}
          {selectedDeviceType === deviceTypes.SENS && <CreateSensDeviceForm />}
        </div>
      </div>
    </div>
  );
};

export default CreateDeviceContent;
