import { FC, PropsWithChildren } from 'react';
import { Navigate, Outlet } from 'react-router-dom';

import { getIsLoggedIn } from '@/redux/auth/auth.selectors.ts';
import { useAppSelector } from '@/redux/hooks.ts';

const NotAuthenticatedRoutes: FC<PropsWithChildren> = () => {
  const isLoggedIn = useAppSelector(getIsLoggedIn);

  if (isLoggedIn) {
    return <Navigate to="/" />;
  }

  return <Outlet />;
};

export default NotAuthenticatedRoutes;
