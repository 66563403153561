import { FC, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import ConfirmDeleteDialog from '@/components/layout/ConfirmDeleteDialog.tsx';
import { Button } from '@/components/ui/button.tsx';
import { logoutState, setName } from '@/redux/auth/auth.slice.ts';
import { deleteFruitSort } from '@/redux/fruitSort/fruitSort.actions.ts';
import { getFruitSorts } from '@/redux/fruitSort/fruitSort.selectors.ts';
import {
  CreateFruitSortResponse,
  FetchFruitSort,
} from '@/redux/fruitSort/fruitSort.types.ts';
import { useAppDispatch, useAppSelector } from '@/redux/hooks.ts';

type FruitSortElementProps = {
  fruitSort: FetchFruitSort;
  index: number;
  length: number;
  onDeleteClick: (id: string) => void;
};

const FruitSortElement: FC<FruitSortElementProps> = ({
  fruitSort,
  index,
  length,
  onDeleteClick,
}) => {
  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);

  const onButtonClick = () => {
    setIsDialogOpen(true);
  };

  const onDeleteConfirmClick = () => {
    onDeleteClick(fruitSort.id);
  };

  return (
    <>
      <li
        className={`${index !== length - 1 ? 'border-b-[1px] border-b-gray-200' : ''} px-5 py-3 flex flex-row justify-between w-full items-center`}
      >
        <p>
          {fruitSort.fruitTypeName}
          {' - '}
          {fruitSort.name}
        </p>
        <Button
          className="bg-[#dc3545] py-3 px-5 text-white"
          onClick={onButtonClick}
        >
          Obriši
        </Button>
      </li>
      <ConfirmDeleteDialog
        headerText="Da li ste sigurni da želite da obrišete sortu?"
        descriptionText="Ova akcija se ne može poništiti. Trajno ćete obrisati sortu."
        onContinueClick={onDeleteConfirmClick}
        isDialogOpen={isDialogOpen}
        setIsDialogOpen={setIsDialogOpen}
      />
    </>
  );
};

const FruitSortSection: FC = () => {
  const fruitSorts = useAppSelector(getFruitSorts);
  const dispatch = useAppDispatch();
  const router = useNavigate();

  const handleErrorResponse = (response: CreateFruitSortResponse) => {
    if (response.error.removeUser) {
      localStorage.removeItem('token');
      localStorage.removeItem('name');
      dispatch(logoutState());
      dispatch(setName(''));
      router('/login');
      return;
    }

    toast.error(response.error.message);
  };

  const onDelete = async (id: string) => {
    // @ts-ignore
    const response = await dispatch(deleteFruitSort({ id })).unwrap();

    if (!response.success) {
      handleErrorResponse(response);
      return;
    }

    toast.success('Vrsta uspešno obrisana');
  };

  return (
    <div className="flex items-start flex-col mt-5 pb-10 w-full">
      <ul className="w-full border-[1px] border-b-gray-200 rounded-lg">
        {fruitSorts.map((fruitSort, index) => (
          <FruitSortElement
            key={`fruit-sort-${fruitSort.name}-${index + 1}`}
            fruitSort={fruitSort}
            index={index}
            length={fruitSorts.length}
            onDeleteClick={onDelete}
          />
        ))}
      </ul>
    </div>
  );
};

export default FruitSortSection;
