import { FC, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import { Button } from '@/components/ui/button.tsx';
import { logoutState, setName } from '@/redux/auth/auth.slice.ts';
import { getAllDevicesForParcel } from '@/redux/device/devices.actions.ts';
import { getDevicesForParcel } from '@/redux/device/devices.selectors.ts';
import {
  FetchDevice,
  GetDevicesResponse,
} from '@/redux/device/devices.types.ts';
import { useAppDispatch, useAppSelector } from '@/redux/hooks.ts';

type ExistingDeviceProps = {
  device: FetchDevice;
  index: number;
  onDeviceClick: (device: FetchDevice) => void;
};

type DevicesContentButtonsSectionProps = {
  onBackClick: () => void;
  onAddDeviceClick: () => void;
};

const ExistingDevice: FC<ExistingDeviceProps> = ({
  device,
  index,
  onDeviceClick,
}) => {
  const onClick = () => {
    onDeviceClick(device);
  };

  return (
    // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions
    <li
      className={`border-[1px] border-b-gray-200 px-5 py-5 flex flex-row justify-between w-full items-center cursor-pointer hover:bg-[#007bff] hover:text-white ${index !== 0 ? 'mt-2' : ''}`}
      onClick={onClick}
    >
      <p>{device.name}</p>
    </li>
  );
};

const DevicesContentButtonsSection: FC<DevicesContentButtonsSectionProps> = ({
  onAddDeviceClick,
  onBackClick,
}) => {
  return (
    <div className="flex flex-row max-md:flex-col w-full py-10 mt-5 gap-3">
      <Button
        className="bg-gray-600 text-white py-3 px-2 "
        onClick={onBackClick}
      >
        Nazad
      </Button>
      <Button
        className="bg-[#28a745] py-3 px-2 text-white"
        type="button"
        onClick={onAddDeviceClick}
      >
        Dodaj uredjaj
      </Button>
    </div>
  );
};

const DevicesContent: FC = () => {
  const params = useParams();
  const router = useNavigate();
  const dispatch = useAppDispatch();
  const devices = useAppSelector(getDevicesForParcel);

  const handleErrorResponse = (response: GetDevicesResponse) => {
    if (response.error.removeUser) {
      localStorage.removeItem('token');
      localStorage.removeItem('name');
      dispatch(logoutState());
      dispatch(setName(''));
      router('/login');
      return;
    }

    toast.error(response.error.message);
  };

  const onDeviceClick = (device: FetchDevice) => {
    router(`/devices/edit/${device.type}/${params.parcelId}/${device.id}`);
  };

  const onBackClick = () => {
    router('/parcels');
  };

  const onAddDeviceClick = () => {
    router(`/devices/create/${params.parcelId}`);
  };

  useEffect(() => {
    const fetchDevices = async () => {
      // @ts-ignore
      const response = await dispatch(
        getAllDevicesForParcel(params.parcelId)
      ).unwrap();
      if (!response.success) {
        handleErrorResponse(response);
      }
    };

    fetchDevices();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="flex w-[88%] flex-col bg-[#85b4f2] bg-opacity-10 max-md:w-full max-md:pb-20">
      <nav className="flex itemst-start bg-white border-b-2 w-full px-10 py-5 max-md:px-5">
        <h1 className="text-3xl font-bold">Uredjaji</h1>
      </nav>
      <div className="flex items-start gap-6 p-10 max-md:p-5">
        <div className="bg-white w-full shadow-lg">
          <div className="flex items-start flex-col mt-10 pb-10 w-[50%] px-10 max-md:w-full max-md:px-5 max-md:mt-5">
            <h2 className="text-xl font-bold mb-5">Uredjaji</h2>
            <ul className="w-full">
              {devices.map((device, index) => (
                <ExistingDevice
                  device={device}
                  index={index}
                  onDeviceClick={onDeviceClick}
                  key={`parcel-device-${device.name}-${index + 1}`}
                />
              ))}
            </ul>
            <DevicesContentButtonsSection
              onAddDeviceClick={onAddDeviceClick}
              onBackClick={onBackClick}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default DevicesContent;
