import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import {
  FERTDevice,
  FetchDevice,
  SensDevice,
  StartDevice,
} from '@/redux/device/devices.types.ts';

type DevicesInitialState = {
  availableZones: number[];
  devices: FetchDevice[];
  startDeviceForEdit: StartDevice;
  fertDeviceForEdit: FERTDevice;
  sensDeviceForEdit: SensDevice;
};

const devicesInitialState: DevicesInitialState = {
  availableZones: [],
  devices: [],
  startDeviceForEdit: null,
  fertDeviceForEdit: null,
  sensDeviceForEdit: null,
};

const devicesSlice = createSlice({
  name: 'devicesSlice',
  initialState: devicesInitialState,
  reducers: {
    setAvailableZones: (state, action: PayloadAction<number[]>) => {
      state.availableZones = action.payload;
    },
    setDevices: (state, action: PayloadAction<FetchDevice[]>) => {
      state.devices = action.payload;
    },
    setStartDeviceForEdit: (state, action: PayloadAction<StartDevice>) => {
      state.startDeviceForEdit = action.payload;
    },
    setFERTDeviceForEdit: (state, action: PayloadAction<FERTDevice>) => {
      state.fertDeviceForEdit = action.payload;
    },
    setSensDeviceForEdit: (state, action: PayloadAction<SensDevice>) => {
      state.sensDeviceForEdit = action.payload;
    },
  },
});

export const {
  setAvailableZones,
  setDevices,
  setStartDeviceForEdit,
  setFERTDeviceForEdit,
  setSensDeviceForEdit,
} = devicesSlice.actions;
export default devicesSlice.reducer;
