import { zodResolver } from '@hookform/resolvers/zod';
import { FC } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import CustomFormCombobox, {
  ComboboxItemType,
} from '@/components/controls/CustomFormCombobox.tsx';
import CustomFormInput from '@/components/controls/CustomFormInput.tsx';
import CustomFormSwitch from '@/components/controls/CustomFormSwitch.tsx';
import { Button } from '@/components/ui/button.tsx';
import { Form } from '@/components/ui/form.tsx';
import { logoutState, setName } from '@/redux/auth/auth.slice.ts';
import { useAppDispatch, useAppSelector } from '@/redux/hooks.ts';
import { createParcel } from '@/redux/parcels/parcels.actions.ts';
import { CreateParcelResponse } from '@/redux/parcels/parcels.types.ts';
import { getDropdownUsers } from '@/redux/users/user.selectors.ts';
import { createParcelFormData } from '@/zod/types.ts';

export type AddParcelFormData = {
  userId: string;
  name: string;
  numberOfZones: number;
  place: string;
  weatherForecast: string;
  simCardNumber: string;
  boundaryForDryLand: number;
  boundaryForWetlands: number;
  humiditySensor: boolean;
  secondHumiditySensor: boolean;
  supplementMixer: boolean;
  supplement: boolean;
  voltage: boolean;
  rawSensorValues: boolean;
};

const AddParcelForm: FC = () => {
  const usersForParcels = useAppSelector(getDropdownUsers);
  const comboboxContent: ComboboxItemType[] = usersForParcels.map((user) => {
    return {
      value: user.id,
      label: user.name,
    };
  });
  const form = useForm({
    resolver: zodResolver(createParcelFormData),
    defaultValues: {
      userId: '',
      name: '',
      numberOfZones: 1,
      place: '',
      weatherForecast: 'Novi Sad',
      simCardNumber: '',
      boundaryForDryLand: 0,
      boundaryForWetlands: 0,
      humiditySensor: false,
      secondHumiditySensor: false,
      supplementMixer: false,
      supplement: false,
      voltage: false,
      rawSensorValues: true,
    },
  });
  const router = useNavigate();
  const dispatch = useAppDispatch();

  const onGiveUpClick = () => {
    router('/parcels');
  };

  const handleErrorResponse = (response: CreateParcelResponse) => {
    if (response.error.removeUser) {
      localStorage.removeItem('token');
      localStorage.removeItem('name');
      dispatch(logoutState());
      dispatch(setName(''));
      router('/login');
      return;
    }

    toast.error(response.error.message);
  };

  const onSubmit = async (data: AddParcelFormData) => {
    // @ts-ignore
    const response = await dispatch(createParcel(data)).unwrap();

    if (!response.success) {
      handleErrorResponse(response);
      return;
    }

    toast.success('Uspešno kreirana parcela');
    router('/parcels');
  };

  return (
    <div className="flex w-[88%] flex-col bg-[#85b4f2] bg-opacity-10 max-md:w-full">
      <nav className="flex itemst-start bg-white border-b-2 w-full px-10 py-5 max-md:px-5">
        <div className="text-3xl font-bold">Dodaj novog distributera</div>
      </nav>
      <Form
        reset={form.reset}
        formState={form.formState}
        clearErrors={form.clearErrors}
        control={form.control}
        getFieldState={form.getFieldState}
        getValues={form.getValues}
        handleSubmit={form.handleSubmit}
        register={form.register}
        resetField={form.resetField}
        setError={form.setError}
        setFocus={form.setFocus}
        setValue={form.setValue}
        trigger={form.trigger}
        unregister={form.unregister}
        watch={form.watch}
      >
        <form
          className="flex items-start gap-6 p-10 max-md:p-5"
          onSubmit={form.handleSubmit(onSubmit)}
        >
          <div className="bg-white w-full shadow-lg">
            <nav className="flex itemst-start bg-white border-b-2 w-full px-5 py-3">
              <div className="text-xl font-bold">Nova parcela</div>
            </nav>
            <div className="border-b border-gray-900/10 pt-5 px-5 text-sm">
              <div className=" flex flex-col">
                <CustomFormCombobox
                  customForm={form}
                  name="userId"
                  labelText="Korisnik"
                  formItemStyle="flex flex-row max-md:flex-col w-full border-b-2 border-dotted pb-10 max-md:py-5"
                  labelStyle="max-md:w-full w-[15%]"
                  buttonStyle="max-md:w-full w-[40%] px-3 py-2 justify-between"
                  items={comboboxContent}
                  placeholder="Odaberite korisnika"
                />
                <CustomFormInput
                  customForm={form}
                  name="name"
                  labelText="Naziv parcele"
                  formItemStyle="flex flex-row max-md:flex-col w-full border-b-2 border-dotted pb-10 py-10 max-md:py-5"
                  labelStyle="max-md:w-full w-[15%]"
                  divStyle="max-md:w-full w-[85%]"
                  type="text"
                />
                <CustomFormInput
                  customForm={form}
                  name="numberOfZones"
                  labelText="Broj zona"
                  formItemStyle="flex flex-row max-md:flex-col w-full border-b-2 border-dotted pb-10 py-10 max-md:py-5"
                  labelStyle="max-md:w-full w-[15%]"
                  divStyle="max-md:w-full w-[85%]"
                  type="number"
                />
                <CustomFormInput
                  customForm={form}
                  name="place"
                  labelText="Mesto"
                  formItemStyle="flex flex-row max-md:flex-col w-full border-b-2 border-dotted pb-10 py-10 max-md:py-5"
                  labelStyle="max-md:w-full w-[15%]"
                  divStyle="max-md:w-full w-[85%]"
                  type="text"
                />
                <CustomFormCombobox
                  customForm={form}
                  name="weatherForecast"
                  labelText="Vremenska prognoza"
                  formItemStyle="flex flex-row max-md:flex-col w-full border-b-2 border-dotted pb-10 py-10 max-md:py-5"
                  labelStyle="max-md:w-full w-[15%]"
                  buttonStyle="max-md:w-full w-[40%] px-3 py-2 justify-between"
                  items={[{ value: 'Novi Sad', label: 'Novi Sad' }]}
                  placeholder="Odaberite vremensku prognozu"
                />
                <CustomFormInput
                  customForm={form}
                  name="simCardNumber"
                  labelText="Broj SIM kartice"
                  formItemStyle="flex flex-row max-md:flex-col w-full border-b-2 border-dotted pb-10 py-10 max-md:py-5"
                  labelStyle="max-md:w-full w-[15%]"
                  divStyle="max-md:w-full w-[85%]"
                  type="text"
                />
                <CustomFormSwitch
                  customForm={form}
                  name="humiditySensor"
                  labelText="Senzor vlažnosti"
                  formItemStyle="flex flex-row max-md:flex-col w-full border-b-2 border-dotted pb-10 pt-5 max-md:py-5 gap-2 md:items-center"
                  labelStyle="max-md:w-full text-sm md:text-center"
                />
                <CustomFormSwitch
                  customForm={form}
                  name="secondHumiditySensor"
                  labelText="Drugi senzor vlažnosti"
                  formItemStyle="flex flex-row max-md:flex-col w-full border-b-2 border-dotted pb-10 pt-5 max-md:py-5 gap-2 md:items-center"
                  labelStyle="max-md:w-full text-sm md:text-center"
                />
                <CustomFormSwitch
                  customForm={form}
                  name="supplementMixer"
                  labelText="Mikser prihrane"
                  formItemStyle="flex flex-row max-md:flex-col w-full border-b-2 border-dotted pb-10 pt-5 max-md:py-5 gap-2 md:items-center"
                  labelStyle="max-md:w-full text-sm md:text-center"
                />
                <CustomFormSwitch
                  customForm={form}
                  name="supplement"
                  labelText="Prihrana"
                  formItemStyle="flex flex-row max-md:flex-col w-full border-b-2 border-dotted pb-10 pt-5 max-md:py-5 gap-2 md:items-center"
                  labelStyle="max-md:w-full text-sm md:text-center"
                />
                <CustomFormSwitch
                  customForm={form}
                  name="voltage"
                  labelText="Napon"
                  formItemStyle="flex flex-row max-md:flex-col w-full border-b-2 border-dotted pb-10 pt-5 max-md:py-5 gap-2 md:items-center"
                  labelStyle="max-md:w-full text-sm md:text-center"
                />
                <CustomFormSwitch
                  customForm={form}
                  name="rawSensorValues"
                  labelText="Neobradjene vrednosti senzora"
                  formItemStyle="flex flex-row max-md:flex-col w-full border-b-2 border-dotted pb-10 pt-5 max-md:py-5 gap-2 md:items-center"
                  labelStyle="max-md:w-full text-sm md:text-center"
                />
                <CustomFormInput
                  customForm={form}
                  name="boundaryForDryLand"
                  labelText="Granica - suvo zemljište"
                  formItemStyle="flex flex-row max-md:flex-col w-full border-b-2 border-dotted pb-10 py-10 max-md:py-5"
                  labelStyle="max-md:w-full w-[15%]"
                  divStyle="max-md:w-full w-[85%]"
                  type="text"
                />
                <CustomFormInput
                  customForm={form}
                  name="boundaryForWetlands"
                  labelText="Granica - vlažno zemljište"
                  formItemStyle="flex flex-row max-md:flex-col w-full border-b-2 border-dotted pb-10 py-10 max-md:py-5"
                  labelStyle="max-md:w-full w-[15%]"
                  divStyle="max-md:w-full w-[85%]"
                  type="text"
                />
                <div className="flex flex-row max-md:flex-col w-full py-10">
                  <span className="max-md:w-full w-[15%]" />
                  <div className="w-[85%] max-md:w-full flex flex-row gap-5">
                    <Button
                      className="bg-gray-600 text-white py-3 px-2"
                      type="button"
                      onClick={onGiveUpClick}
                    >
                      Odustani
                    </Button>
                    <Button
                      className="bg-[#007bff] py-3 px-2 text-white"
                      type="submit"
                    >
                      Dodaj parcelu
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </Form>
    </div>
  );
};

export default AddParcelForm;
