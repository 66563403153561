import { zodResolver } from '@hookform/resolvers/zod';
import { FC } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import CustomFormInput from '@/components/controls/CustomFormInput.tsx';
import { Button } from '@/components/ui/button.tsx';
import { Form } from '@/components/ui/form.tsx';
import { adminLogin } from '@/redux/auth/auth.actions.ts';
import { useAppDispatch } from '@/redux/hooks.ts';
import { loginSchema } from '@/zod/types.ts';

export type LoginFormData = {
  username: string;
  password: string;
};

const LoginForm: FC = () => {
  const loginForm = useForm<LoginFormData>({
    resolver: zodResolver(loginSchema),
    defaultValues: {
      username: '',
      password: '',
    },
  });
  const dispatch = useAppDispatch();
  const router = useNavigate();

  const onSubmit = async (data: LoginFormData) => {
    // @ts-ignore
    const response = await dispatch(adminLogin(data)).unwrap();

    if (!response.success) {
      loginForm.setError('password', { message: 'Greška prilikom prijave' });
      return;
    }

    router('/');
  };

  return (
    <Form
      reset={loginForm.reset}
      formState={loginForm.formState}
      clearErrors={loginForm.clearErrors}
      control={loginForm.control}
      getFieldState={loginForm.getFieldState}
      getValues={loginForm.getValues}
      handleSubmit={loginForm.handleSubmit}
      register={loginForm.register}
      resetField={loginForm.resetField}
      setError={loginForm.setError}
      setFocus={loginForm.setFocus}
      setValue={loginForm.setValue}
      trigger={loginForm.trigger}
      unregister={loginForm.unregister}
      watch={loginForm.watch}
    >
      <form
        className="flex flex-col md:gap-6 pl-5 max-md:p-5 w-full"
        onSubmit={loginForm.handleSubmit(onSubmit)}
      >
        <CustomFormInput
          customForm={loginForm}
          name="username"
          labelText="Username"
          formItemStyle="flex flex-col max-md:flex-col w-full max-md:py-5"
          labelStyle="hidden"
          divStyle="max-md:w-full w-[85%]"
          type="text"
          placeholder="Email"
          inputStyle="border-t-0 border-x-0 border-b-[1px] focus-visible:border-b-[#007bff] rounded-[0px] ring-[0px] focus-visible:ring-0"
        />
        <CustomFormInput
          customForm={loginForm}
          name="password"
          labelText="Lozinka"
          formItemStyle="flex flex-col max-md:flex-col w-full max-md:py-5"
          labelStyle="hidden"
          divStyle="max-md:w-full w-[85%]"
          type="password"
          placeholder="Lozinka"
          inputStyle="border-t-0 border-x-0 border-b-[1px] focus-visible:border-b-[#007bff] rounded-[0px] ring-[0px] focus-visible:ring-0"
        />
        <div>
          <Button className="bg-[#007bff] py-3 px-5 text-white" type="submit">
            Prijava
          </Button>
        </div>
      </form>
    </Form>
  );
};

export default LoginForm;
