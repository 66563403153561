import { FC } from 'react';
import { Link, useNavigate } from 'react-router-dom';

import { Button } from '@/components/ui/button.tsx';
import { logoutState, setName } from '@/redux/auth/auth.slice.ts';
import { useAppDispatch } from '@/redux/hooks.ts';

const Navbar: FC = () => {
  const router = useNavigate();
  const dispatch = useAppDispatch();

  const onLogoutClick = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('name');
    dispatch(logoutState());
    dispatch(setName(''));
    router('/login');
  };

  return (
    <header className="sticky top-0 flex min-h-[70px] items-center gap-4 border-b bg-[#007bff] px-4 md:px-6 justify-between max-md:gap-5 z-10">
      <nav className="flex-row gap-6 text-lg flex md:items-center md:gap-5 md:text-sm lg:gap-6">
        <Link
          to="/"
          className="flex flex-row items-center gap-5 text-xl font-bold"
        >
          <img src="/sw-logo.png" alt="sw logo" className="w-[20px]" />
          <span className="max-md:hidden text-white flex flex-row gap-2">
            SmartWatering
          </span>
          <span className="md:hidden text-white flex flex-row gap-2">SW</span>
        </Link>
      </nav>
      <div className="flex  items-center gap-4 md:ml-auto md:gap-2 lg:gap-4 text-white">
        <div className="ml-auto flex-1 sm:flex-initial">
          <div className="relative">
            <Button
              className="bg-transparent hover:bg-transparent"
              onClick={onLogoutClick}
            >
              Odjava
            </Button>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Navbar;
