import { FC } from 'react';

import EditDistributorTabs from '@/components/layout/distributors/EditDistributorTabs.tsx';
import MobileFooterNavigation from '@/components/layout/MobileFooterNavigation.tsx';
import Navbar from '@/components/layout/Navbar.tsx';
import Sidebar from '@/components/layout/Sidebar.tsx';

const EditDistributorPage: FC = () => {
  return (
    <div className="flex h-screen w-full flex-col">
      <Navbar />
      <div className="flex flex-1 flex-row">
        <Sidebar />
        <EditDistributorTabs />
      </div>
      <MobileFooterNavigation />
    </div>
  );
};

export default EditDistributorPage;
