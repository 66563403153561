import { FC, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import AddParcelForm from '@/components/forms/AddParcelForm.tsx';
import MobileFooterNavigation from '@/components/layout/MobileFooterNavigation.tsx';
import Navbar from '@/components/layout/Navbar.tsx';
import Sidebar from '@/components/layout/Sidebar.tsx';
import { logoutState, setName } from '@/redux/auth/auth.slice.ts';
import { useAppDispatch } from '@/redux/hooks.ts';
import { getUsersForParcelCombobox } from '@/redux/users/users.actions.ts';
import { GetUserDropdownResponse } from '@/redux/users/users.types.ts';

const CreateParcelPage: FC = () => {
  const router = useNavigate();
  const dispatch = useAppDispatch();

  const handleErrorResponse = (response: GetUserDropdownResponse) => {
    if (response.error.removeUser) {
      localStorage.removeItem('token');
      localStorage.removeItem('name');
      dispatch(logoutState());
      dispatch(setName(''));
      router('/login');
      return;
    }

    toast.error(response.error.message);
  };

  useEffect(() => {
    const fetchUsersForCombobox = async () => {
      // @ts-ignore
      const response = await dispatch(getUsersForParcelCombobox()).unwrap();

      if (!response.success) {
        handleErrorResponse(response);
      }
    };

    fetchUsersForCombobox();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="flex h-screen w-full flex-col">
      <Navbar />
      <div className="flex flex-1 flex-row">
        <Sidebar />
        <AddParcelForm />
      </div>
      <MobileFooterNavigation />
    </div>
  );
};

export default CreateParcelPage;
