import { FC } from 'react';

import AddFruitTypeForm from '@/components/forms/AddFruitTypeForm.tsx';
import FruitTypesSection from '@/components/layout/fruits/FruitTypesSection.tsx';

const FruitTypesContent: FC = () => {
  return (
    <div className="flex items-start gap-6 px-10 pt-10 max-md:p-5">
      <div className="bg-white w-full shadow-lg flex flex-row max-md:flex-col md:gap-5">
        <div className="w-[50%] px-5 pt-5 max-md:w-full">
          <h2 className="text-2xl font-bold">Dodaj Vrstu</h2>
          <AddFruitTypeForm />
        </div>
        <div className="w-[50%] px-5 pt-5 max-md:w-full">
          <h2 className="text-2xl font-bold">Sve Vrste</h2>
          <FruitTypesSection />
        </div>
      </div>
    </div>
  );
};

export default FruitTypesContent;
