import { FC } from 'react';

import MobileFooterNavigation from '@/components/layout/MobileFooterNavigation.tsx';
import Navbar from '@/components/layout/Navbar.tsx';
import Sidebar from '@/components/layout/Sidebar.tsx';

const HomePage: FC = () => {
  return (
    <div className="flex h-screen w-full flex-col">
      <Navbar />
      <div className="flex flex-1 flex-row">
        <Sidebar />
        <div className="flex w-[88%] flex-col bg-[#85b4f2] bg-opacity-10 max-md:w-full">
          <nav className="flex itemst-start bg-white border-b-2 w-full px-10 py-5 max-md:px-5">
            <div className="text-3xl font-bold">Početna</div>
          </nav>
        </div>
      </div>
      <MobileFooterNavigation />
    </div>
  );
};

export default HomePage;
