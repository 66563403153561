import { IconProp } from '@fortawesome/fontawesome-svg-core';
import {
  faHomeLg,
  faLeaf,
  faMap,
  faUsers,
  faUserSecret,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FC, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import { getName } from '@/redux/auth/auth.selectors.ts';
import { login, setName } from '@/redux/auth/auth.slice.ts';
import { useAppDispatch, useAppSelector } from '@/redux/hooks.ts';
import { getFromLocalStorage } from '@/services/local.storage.ts';

type SidebarNavigationLinkProps = {
  text: string;
  icon: IconProp;
  link: string;
};

const SidebarNavigationLink: FC<SidebarNavigationLinkProps> = ({
  text,
  link,
  icon,
}) => {
  return (
    <Link
      to={link}
      className="font-light flex flex-row items-center gap-5 hover:bg-[#007bff] hover:text-white w-full px-5 py-3"
    >
      <FontAwesomeIcon icon={icon} className="w-5 h-5" />
      {text}
    </Link>
  );
};

const SidebarNavigation: FC = () => {
  return (
    <nav className="text-sm mt-2 text-[#686a76] items-start w-full">
      <SidebarNavigationLink text="Početna" icon={faHomeLg} link="/" />
      <SidebarNavigationLink text="Korisnici" icon={faUsers} link="/users" />
      <SidebarNavigationLink
        text="Distributeri"
        icon={faUserSecret}
        link="/distributors"
      />
      <SidebarNavigationLink text="Parcele" icon={faMap} link="/parcels" />
      <SidebarNavigationLink text="Vrste" icon={faLeaf} link="/fruit-types" />
    </nav>
  );
};

const Sidebar: FC = () => {
  const [isLoading, setIsLoading] = useState(true);
  const dispatch = useAppDispatch();
  const name = useAppSelector(getName);

  useEffect(() => {
    if (getFromLocalStorage('token')) {
      dispatch(login());
      dispatch(
        setName({
          name: getFromLocalStorage('name'),
        })
      );
    }

    setIsLoading(false);
  }, [dispatch]);

  if (isLoading) {
    return <div />;
  }

  return (
    <div className="gap-2 flex flex-col w-[12%] min-w-[250px] border-r-2 max-md:hidden overflow-y-hidden items-start">
      <div className="flex flex-row gap-2 w-full px-5 py-5">
        <div className="">
          <img
            src="/avatar-1.jpg"
            alt="avatar"
            className="rounded-full w-16 h-16"
          />
        </div>
        <div className="flex flex-col justify-center gap-2">
          <h1 className="text-xl font-bold">{name}</h1>
          <p className="text-lg font-linght">Smart Watering</p>
        </div>
      </div>
      <span className="px-5 pt-3 text-sm text-[#ccc]">NAVIGACIJA</span>
      <SidebarNavigation />
    </div>
  );
};

export default Sidebar;
