import { FC } from 'react';
import ReactPaginate from 'react-paginate';

type PaginationSectionProps = {
  page: number;
  rowsPerPage: number;
  filteredNumber: number;
  totalNumber: number;
  onPageChangeClick: (selectedPage: number) => void;
  entity: string;
};

const PaginationSection: FC<PaginationSectionProps> = ({
  page,
  rowsPerPage,
  filteredNumber,
  totalNumber,
  onPageChangeClick,
  entity,
}) => {
  const getTextForPagination = () => {
    if (filteredNumber === 0) {
      return `Prikaz 0 do 0 od ukupno 0 ${entity}`;
    }

    const text = `Prikaz ${page * rowsPerPage + 1} do ${filteredNumber > rowsPerPage ? rowsPerPage * page + rowsPerPage : filteredNumber} od ukupno ${Math.min((page + 1) * rowsPerPage, filteredNumber)} ${entity}`;

    if (filteredNumber !== totalNumber) {
      return `${text} (filtrirano od ukupno ${totalNumber} ${entity}) `;
    }

    return text;
  };

  return (
    <div className="flex flex-row max-md:flex-col justify-between w-full md:pt-10 pt-5 pb-5 items-center">
      <p className="mx-5 max-md:mb-5 text-center items-center">
        {getTextForPagination()}
      </p>
      <ReactPaginate
        breakLabel="..."
        nextLabel="Sledeća"
        onPageChange={({ selected }) => onPageChangeClick(selected)}
        pageRangeDisplayed={1}
        breakClassName="breakClass"
        pageCount={Math.ceil(filteredNumber / rowsPerPage)}
        previousLabel="Prethodna"
        renderOnZeroPageCount={null}
        className="flex mx-5 rounded-lg pagination"
        activeClassName="activeClass"
        disabledClassName="disabledClass"
      />
    </div>
  );
};

export default PaginationSection;
