import debounce from 'lodash/debounce';
import { ChangeEvent, FC, useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import CustomTableHeader, {
  TableHeaderType,
} from '@/components/layout/CustomTableHeader.tsx';
import PaginationSection from '@/components/layout/PaginationSection.tsx';
import { Input } from '@/components/ui/input.tsx';
import {
  Table,
  TableBody,
  TableCell,
  TableRow,
} from '@/components/ui/table.tsx';
import { logoutState, setName } from '@/redux/auth/auth.slice.ts';
import { useAppDispatch, useAppSelector } from '@/redux/hooks.ts';
import { getFilteredParcels } from '@/redux/parcels/parcels.actions.ts';
import {
  getFilteredNumberOfParcels,
  getParcels,
  getTotalNumberOfParcels,
} from '@/redux/parcels/parcels.selectors.ts';
import { GetParcelsResponse } from '@/redux/parcels/parcels.types.ts';
import { formatSyncDate } from '@/utils/dateUtil.ts';

const parcelsTableHeaders: TableHeaderType[] = [
  {
    id: 'user',
    text: 'Korisnik',
    className: '',
  },
  {
    id: 'name',
    text: 'Parcela',
    className: '',
  },
  {
    id: 'numberOfIrrigation',
    text: 'Navodnjavanja',
    className: 'max-md:hidden',
  },
  { id: 'humidity', text: 'Vlažnost', className: 'max-md:hidden' },
  { id: 'lastSync', text: 'Sinhronizovan', className: 'max-md:hidden' },
  { id: 'devices', text: 'Lista uredjaja', className: '' },
];

const ParcelsTableSection: FC = () => {
  const router = useNavigate();
  const parcels = useAppSelector(getParcels);
  const totalNumberOfParcels = useAppSelector(getTotalNumberOfParcels);
  const filteredNumberOfParcels = useAppSelector(getFilteredNumberOfParcels);
  const dispatch = useAppDispatch();
  const [page, setPage] = useState<number>(0);
  const rowsPerPage = 10;
  const [sortColumn, setSortColumn] = useState<string>('');
  const [isSortAsc, setIsSortAsc] = useState<boolean>(true);
  const [inputText, setInputText] = useState<string>('');

  const createQueryParams = (
    realPage: number,
    realSortColumn: string,
    realSortAsc: boolean,
    realInputText: string
  ) => {
    return `?page=${realPage}&perPage=${rowsPerPage}${realSortColumn ? `&sortColumn=${realSortColumn}&sortDirection=${realSortAsc ? 'ASC' : 'DESC'}` : ''}${realInputText ? `&inputText=${realInputText}` : ''}`;
  };

  const callFetchParcels = async (queryParams: string) => {
    // @ts-ignore
    return dispatch(getFilteredParcels(queryParams)).unwrap();
  };

  const handleErrorResponse = (response: GetParcelsResponse) => {
    if (response.error.removeUser) {
      localStorage.removeItem('token');
      localStorage.removeItem('name');
      dispatch(logoutState());
      dispatch(setName(''));
      router('/login');
      return;
    }

    toast.error(response.error.message);
  };

  const onDebounceCall = async (value: string) => {
    setInputText(value);
    setPage(0);
    const queryParams = createQueryParams(0, sortColumn, isSortAsc, value);
    await callFetchParcels(queryParams);
  };

  const debouncedChangeHandler = debounce((value: string) => {
    onDebounceCall(value);
  }, 500);

  const handleChange = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { value } = e.target;
    debouncedChangeHandler(value);
  };

  const onColumnClick = async (columnName: string) => {
    if (columnName === sortColumn) {
      setIsSortAsc(!isSortAsc);
      const queryParams = createQueryParams(
        page,
        sortColumn,
        !isSortAsc,
        inputText
      );
      await callFetchParcels(queryParams);
      return;
    }

    setSortColumn(columnName);
    setIsSortAsc(true);
    const queryParams = createQueryParams(page, columnName, true, inputText);
    await callFetchParcels(queryParams);
  };

  const onPageChangeClick = async (selectedPage: number) => {
    const queryParams = createQueryParams(
      selectedPage,
      sortColumn,
      isSortAsc,
      inputText
    );
    setPage(selectedPage);
    await callFetchParcels(queryParams);
  };

  const onParcelClick = (parcelId: string) => {
    router(`/parcels/edit/${parcelId}`);
  };

  useEffect(() => {
    const fetchParcels = async () => {
      const queryParams = createQueryParams(
        page,
        sortColumn,
        isSortAsc,
        inputText
      );
      const response = await callFetchParcels(queryParams);
      if (!response.success) {
        handleErrorResponse(response);
      }
    };

    fetchParcels();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <div className="pt-5 px-5 pb-2 flex flex-row items-center justify-end gap-5">
        <p>Pretraga:</p>
        <div>
          <Input className="rounded-0" onChange={handleChange} />
        </div>
      </div>
      <div className="border-b border-gray-900/10 pt-5 md:px-5 text-sm">
        <div className="flex flex-col">
          <Table>
            <CustomTableHeader
              tableName="parcels"
              tableHeaders={parcelsTableHeaders}
              onHeaderCellClick={onColumnClick}
            />
            <TableBody>
              {parcels.map((parcel, index) => (
                <TableRow
                  key={`parcels-table-data-parcel-${index + 1}`}
                  className="cursor-pointer"
                >
                  <TableCell onClick={() => onParcelClick(parcel.id)}>
                    {parcel.user}
                  </TableCell>
                  <TableCell onClick={() => onParcelClick(parcel.id)}>
                    {parcel.name}
                  </TableCell>
                  <TableCell
                    className="max-md:hidden"
                    onClick={() => onParcelClick(parcel.id)}
                  >
                    0
                  </TableCell>
                  <TableCell
                    className="max-md:hidden"
                    onClick={() => onParcelClick(parcel.id)}
                  >
                    0 %
                  </TableCell>
                  <TableCell
                    className="max-md:hidden"
                    onClick={() => onParcelClick(parcel.id)}
                  >
                    {formatSyncDate(parcel.lastSync)}
                  </TableCell>
                  <TableCell>
                    <Link
                      to={`/devices/${parcel.id}`}
                      className="underline hover:text-[#007bff]"
                    >
                      Uredjaji
                    </Link>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </div>
      </div>
      <PaginationSection
        page={page}
        rowsPerPage={rowsPerPage}
        filteredNumber={filteredNumberOfParcels}
        totalNumber={totalNumberOfParcels}
        onPageChangeClick={onPageChangeClick}
        entity="parcele"
      />
    </>
  );
};

export default ParcelsTableSection;
