import { zodResolver } from '@hookform/resolvers/zod';
import { FC, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import CustomFormDropDown from '@/components/controls/CustomFormDropDown.tsx';
import CustomFormInput from '@/components/controls/CustomFormInput.tsx';
import CustomFormMultiSelect, {
  MultiSelectItem,
} from '@/components/controls/CustomFormMultiSelect.tsx';
import DeviceFormButtonSection from '@/components/forms/devices/DeviceFormButtonSection.tsx';
import SensDeviceSensorsForm from '@/components/forms/devices/SensDeviceSensorsForm.tsx';
import StartDeviceHumidityForm from '@/components/forms/devices/StartDeviceHumidityForm.tsx';
import ConfirmDeleteDialog from '@/components/layout/ConfirmDeleteDialog.tsx';
import { Form } from '@/components/ui/form.tsx';
import { logoutState, setName } from '@/redux/auth/auth.slice.ts';
import {
  deleteSensDeviceById,
  updateSensDevice,
} from '@/redux/device/devices.actions.ts';
import {
  getAvailableZones,
  getSensDeviceForEdit,
} from '@/redux/device/devices.selectors.ts';
import { CreateDeviceResponse } from '@/redux/device/devices.types.ts';
import { useAppDispatch, useAppSelector } from '@/redux/hooks.ts';
import {
  formAvailableZonesContent,
  sensDeviceTypes,
} from '@/utils/device.util.ts';
import { editSensDeviceSchema } from '@/zod/types.ts';

export type EditSensDeviceFormData = {
  id: string;
  parcelId: string;
  name: string;
  color: string;
  zonesForDevice: string[];
  standbyModeTime: string;
  sensSensorType: string;
  humidityOne: string;
  humidityTwo: string;
  flowMeter: string;
  soilTemperature: string;
  soilSalinity: string;
  soilPH: string;
  airTemperature: string;
  airHumidity: string;
  windDirection: string;
  windSpeed: string;
  solarRadiation: string;
  amountOfPrecipitation: string;
  batteryVoltage: string;
  notificationForWrongFlowMeter: string;
  pressureSensor: string;
};

type EditSensDeviceFormProps = {
  transformedSensDevice: EditSensDeviceFormData;
};

const EditSensDeviceForm: FC<EditSensDeviceFormProps> = ({
  transformedSensDevice,
}) => {
  const device = useAppSelector(getSensDeviceForEdit);
  const params = useParams();
  const form = useForm({
    resolver: zodResolver(editSensDeviceSchema),
    defaultValues: {
      id: '',
      parcelId: params.parcelId as string,
      name: '',
      color: '#4613ad',
      zonesForDevice: [],
      standbyModeTime: '0',
      sensSensorType: '30',
      humidityOne: 'true',
      humidityTwo: 'false',
      flowMeter: 'true',
      soilTemperature: 'true',
      soilSalinity: 'true',
      soilPH: 'true',
      airTemperature: 'false',
      airHumidity: 'false',
      windDirection: 'false',
      windSpeed: 'false',
      solarRadiation: 'false',
      amountOfPrecipitation: 'false',
      batteryVoltage: 'true',
      notificationForWrongFlowMeter: 'false',
      pressureSensor: 'false',
    },
  });
  const dispatch = useAppDispatch();
  const router = useNavigate();
  const availableZones = useAppSelector(getAvailableZones);
  const [multiSelectData, setMultiSelectData] = useState<MultiSelectItem[]>([]);
  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);

  const handleErrorResponse = (response: CreateDeviceResponse) => {
    if (response.error.removeUser) {
      localStorage.removeItem('token');
      localStorage.removeItem('name');
      dispatch(logoutState());
      dispatch(setName(''));
      router('/login');
      return;
    }

    toast.error(response.error.message);
  };

  const onGiveUpClick = () => {
    router(`/devices/${params.parcelId}`);
  };

  const onSubmit = async (data: EditSensDeviceFormData) => {
    // @ts-ignore
    const response = await dispatch(updateSensDevice(data)).unwrap();

    if (!response.success) {
      handleErrorResponse(response);
      return;
    }

    toast.success('Uspešno ažuriran uredjaj');
    router(`/devices/${params.parcelId}`);
  };

  const onDeleteClick = () => {
    setIsDialogOpen(true);
  };

  const onDeleteDeviceClick = async () => {
    // @ts-ignore
    const response = await dispatch(
      deleteSensDeviceById(params.deviceId)
    ).unwrap();
    if (!response.success) {
      handleErrorResponse(response);
      return;
    }

    toast.success('Uredjaj je uspešno izbrisan');
    router(`/devices/${params.parcelId}`);
  };

  useEffect(() => {
    if (transformedSensDevice) {
      form.reset({
        id: params.deviceId,
        parcelId: params.parcelId,
        name: transformedSensDevice.name,
        color: transformedSensDevice.color,
        zonesForDevice: transformedSensDevice.zonesForDevice,
        standbyModeTime: transformedSensDevice.standbyModeTime,
        sensSensorType: transformedSensDevice.sensSensorType,
        humidityOne: transformedSensDevice.humidityOne,
        humidityTwo: transformedSensDevice.humidityTwo,
        flowMeter: transformedSensDevice.flowMeter,
        soilTemperature: transformedSensDevice.soilTemperature,
        soilSalinity: transformedSensDevice.soilSalinity,
        soilPH: transformedSensDevice.soilPH,
        airTemperature: transformedSensDevice.airTemperature,
        airHumidity: transformedSensDevice.airHumidity,
        windDirection: transformedSensDevice.windDirection,
        windSpeed: transformedSensDevice.windSpeed,
        solarRadiation: transformedSensDevice.solarRadiation,
        amountOfPrecipitation: transformedSensDevice.amountOfPrecipitation,
        batteryVoltage: transformedSensDevice.batteryVoltage,
        notificationForWrongFlowMeter:
          transformedSensDevice.notificationForWrongFlowMeter,
        pressureSensor: transformedSensDevice.pressureSensor,
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [transformedSensDevice]);

  useEffect(() => {
    setMultiSelectData(formAvailableZonesContent(device, availableZones));
  }, [device, availableZones]);

  return (
    <>
      <Form
        reset={form.reset}
        formState={form.formState}
        clearErrors={form.clearErrors}
        control={form.control}
        getFieldState={form.getFieldState}
        getValues={form.getValues}
        handleSubmit={form.handleSubmit}
        register={form.register}
        resetField={form.resetField}
        setError={form.setError}
        setFocus={form.setFocus}
        setValue={form.setValue}
        trigger={form.trigger}
        unregister={form.unregister}
        watch={form.watch}
      >
        <form onSubmit={form.handleSubmit(onSubmit)}>
          <div className="border-b border-gray-900/10 pt-5 px-5 text-sm">
            <div className=" flex flex-col">
              <CustomFormInput
                customForm={form}
                name="name"
                labelText="Ime uredjaja"
                formItemStyle="flex flex-row max-md:flex-col w-full border-b-2 border-dotted pb-10 max-md:py-5"
                labelStyle="max-md:w-full w-[15%]"
                divStyle="max-md:w-full w-[85%]"
                type="text"
              />
              <CustomFormInput
                customForm={form}
                name="color"
                labelText="Boja uredjaja"
                formItemStyle="flex flex-col w-full border-b-2 border-dotted pb-10 py-10 max-md:py-5"
                labelStyle="max-md:w-full"
                divStyle="w-[50px]"
                type="color"
              />
              <CustomFormInput
                customForm={form}
                name="standbyModeTime"
                labelText="Vreme u standBy modu"
                formItemStyle="flex flex-row max-md:flex-col w-full border-b-2 border-dotted pb-10 py-10 max-md:py-5"
                labelStyle="max-md:w-full w-[15%]"
                divStyle="max-md:w-full w-[85%]"
                type="number"
              />
              <CustomFormDropDown
                customForm={form}
                name="sensSensorType"
                labelText="Pod vrsta senzora SENTEK"
                formItemStyle="flex w-full flex-col max-md:mt-2 max-md:w-full pb-10 py-10 border-b-2 border-dotted"
                items={sensDeviceTypes}
                placeholder="Odaberite podvrstu senzora"
              />
              <CustomFormMultiSelect
                customForm={form}
                name="zonesForDevice"
                labelText="Zone za uredjaj"
                items={multiSelectData}
                formItemStyle="flex w-full flex-col max-md:mt-2 max-md:w-full pb-10 py-10 border-b-2 border-dotted"
                placeholder="Izaberite zonu/e"
              />
              <StartDeviceHumidityForm form={form} />
              <SensDeviceSensorsForm form={form} />
              <DeviceFormButtonSection
                onGiveUpClick={onGiveUpClick}
                onDeleteDeviceClick={onDeleteClick}
              />
            </div>
          </div>
        </form>
      </Form>
      <ConfirmDeleteDialog
        headerText="Da li ste sigurni da želite da obrišete uredjaj?"
        descriptionText="Ova akcija se ne može poništiti. Trajno ćete obrisati uredjaj."
        onContinueClick={onDeleteDeviceClick}
        isDialogOpen={isDialogOpen}
        setIsDialogOpen={setIsDialogOpen}
      />
    </>
  );
};

export default EditSensDeviceForm;
