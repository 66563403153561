import { FC } from 'react';

import { Button } from '@/components/ui/button.tsx';

type DeviceFormButtonSectionProps = {
  onGiveUpClick: () => void;
  onDeleteDeviceClick: () => void;
};

const DeviceFormButtonSection: FC<DeviceFormButtonSectionProps> = ({
  onGiveUpClick,
  onDeleteDeviceClick,
}) => {
  return (
    <div className="flex flex-row max-md:flex-col w-full py-10">
      <div className="w-[85%] max-md:w-full flex flex-row gap-5">
        <Button
          className="bg-gray-600 text-white py-3 px-2"
          onClick={onGiveUpClick}
        >
          Odustani
        </Button>
        <Button className="bg-[#007bff] py-3 px-2 text-white" type="submit">
          Izmeni uredjaj
        </Button>
        <Button
          className="bg-[#dc3545] py-3 px-2 text-white"
          onClick={onDeleteDeviceClick}
          type="button"
        >
          Obriši uredjaj
        </Button>
      </div>
    </div>
  );
};

export default DeviceFormButtonSection;
