import { zodResolver } from '@hookform/resolvers/zod';
import { FC } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import CustomFormInput from '@/components/controls/CustomFormInput.tsx';
import { Button } from '@/components/ui/button.tsx';
import { Form } from '@/components/ui/form.tsx';
import { logoutState, setName } from '@/redux/auth/auth.slice.ts';
import { createDistributor } from '@/redux/distributors/distributors.actions.ts';
import { CreateDistributorResponse } from '@/redux/distributors/distributors.types.ts';
import { useAppDispatch } from '@/redux/hooks.ts';
import { createDistributorSchema } from '@/zod/types.ts';

export type CreateDistributorFormData = {
  name: string;
  email: string;
  password: string;
  phoneNumber: string;
  address: string;
  place: string;
  country: string;
};

const AddDistributorForm: FC = () => {
  const form = useForm({
    resolver: zodResolver(createDistributorSchema),
    defaultValues: {
      name: '',
      email: '',
      password: '',
      phoneNumber: '',
      address: '',
      place: '',
      country: '',
    },
  });
  const dispatch = useAppDispatch();
  const router = useNavigate();

  const handleErrorResponse = (response: CreateDistributorResponse) => {
    if (response.error.removeUser) {
      localStorage.removeItem('token');
      localStorage.removeItem('name');
      dispatch(logoutState());
      dispatch(setName(''));
      router('/login');
      return;
    }

    toast.error(response.error.message);
  };

  const onGiveUpClick = () => {
    router('/distributors');
  };

  const onSubmit = async (data: CreateDistributorFormData) => {
    // @ts-ignore
    const response = await dispatch(createDistributor(data)).unwrap();

    if (!response.success) {
      handleErrorResponse(response);
      return;
    }

    toast.success('Distributer je uspešno kreiran');
    router('/distributors');
  };

  return (
    <div className="flex w-[88%] flex-col bg-[#85b4f2] bg-opacity-10 max-md:w-full">
      <nav className="flex itemst-start bg-white border-b-2 w-full px-10 py-5 max-md:px-5">
        <div className="text-3xl font-bold">Dodaj novog distributera</div>
      </nav>
      <Form
        reset={form.reset}
        formState={form.formState}
        clearErrors={form.clearErrors}
        control={form.control}
        getFieldState={form.getFieldState}
        getValues={form.getValues}
        handleSubmit={form.handleSubmit}
        register={form.register}
        resetField={form.resetField}
        setError={form.setError}
        setFocus={form.setFocus}
        setValue={form.setValue}
        trigger={form.trigger}
        unregister={form.unregister}
        watch={form.watch}
      >
        <form
          className="flex items-start gap-6 p-10 max-md:p-5"
          onSubmit={form.handleSubmit(onSubmit)}
        >
          <div className="bg-white w-full shadow-lg">
            <nav className="flex itemst-start bg-white border-b-2 w-full px-5 py-3">
              <div className="text-xl font-bold">Novi distributer</div>
            </nav>
            <div className="border-b border-gray-900/10 pt-5 px-5 text-sm">
              <div className=" flex flex-col">
                <CustomFormInput
                  customForm={form}
                  name="name"
                  labelText="Naziv"
                  formItemStyle="flex flex-row max-md:flex-col w-full border-b-2 border-dotted pb-10 max-md:py-5"
                  labelStyle="max-md:w-full w-[15%]"
                  divStyle="max-md:w-full w-[85%]"
                  type="text"
                />
                <CustomFormInput
                  customForm={form}
                  name="email"
                  labelText="Email"
                  formItemStyle="flex flex-row max-md:flex-col w-full border-b-2 border-dotted pb-10 py-10 max-md:py-5"
                  labelStyle="max-md:w-full w-[15%]"
                  divStyle="max-md:w-full w-[85%]"
                  type="text"
                />
                <CustomFormInput
                  customForm={form}
                  name="password"
                  labelText="Lozinka"
                  formItemStyle="flex flex-row max-md:flex-col w-full border-b-2 border-dotted pb-10 py-10 max-md:py-5"
                  labelStyle="max-md:w-full w-[15%]"
                  divStyle="max-md:w-full w-[85%]"
                  type="password"
                />
                <CustomFormInput
                  customForm={form}
                  name="phoneNumber"
                  labelText="Telefon"
                  formItemStyle="flex flex-row max-md:flex-col w-full border-b-2 border-dotted pb-10 py-10 max-md:py-5"
                  labelStyle="max-md:w-full w-[15%]"
                  divStyle="max-md:w-full w-[85%]"
                  type="text"
                />
                <CustomFormInput
                  customForm={form}
                  name="address"
                  labelText="Adresa"
                  formItemStyle="flex flex-row max-md:flex-col w-full border-b-2 border-dotted pb-10 py-10 max-md:py-5"
                  labelStyle="max-md:w-full w-[15%]"
                  divStyle="max-md:w-full w-[85%]"
                  type="text"
                />
                <CustomFormInput
                  customForm={form}
                  name="place"
                  labelText="Mesto"
                  formItemStyle="flex flex-row max-md:flex-col w-full border-b-2 border-dotted pb-10 py-10 max-md:py-5"
                  labelStyle="max-md:w-full w-[15%]"
                  divStyle="max-md:w-full w-[85%]"
                  type="text"
                />
                <CustomFormInput
                  customForm={form}
                  name="country"
                  labelText="Drzava"
                  formItemStyle="flex flex-row max-md:flex-col w-full border-b-2 border-dotted pb-10 py-10 max-md:py-5"
                  labelStyle="max-md:w-full w-[15%]"
                  divStyle="max-md:w-full w-[85%]"
                  type="text"
                />
                <div className="flex flex-row max-md:flex-col w-full py-10">
                  <span className="max-md:w-full w-[15%]" />
                  <div className="w-[85%] max-md:w-full flex flex-row gap-5">
                    <Button
                      className="bg-gray-600 text-white py-3 px-2"
                      onClick={onGiveUpClick}
                    >
                      Odustani
                    </Button>
                    <Button
                      className="bg-[#007bff] py-3 px-2 text-white"
                      type="submit"
                    >
                      Dodaj distributera
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </Form>
    </div>
  );
};

export default AddDistributorForm;
