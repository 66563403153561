import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import debounce from 'lodash/debounce';
import { ChangeEvent, FC, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import CustomTableHeader, {
  TableHeaderType,
} from '@/components/layout/CustomTableHeader.tsx';
import PaginationSection from '@/components/layout/PaginationSection.tsx';
import { Button } from '@/components/ui/button.tsx';
import { Input } from '@/components/ui/input.tsx';
import {
  Table,
  TableBody,
  TableCell,
  TableRow,
} from '@/components/ui/table.tsx';
import { logoutState, setName } from '@/redux/auth/auth.slice.ts';
import {
  getFilteredUsersForDistributor,
  removeDistributorForUser,
} from '@/redux/distributors/distributors.actions.ts';
import {
  getFilteredNumberOfUsersForDistributors,
  getTotalNumberOfUsersForDistributors,
  getUsersForDistributor,
} from '@/redux/distributors/distributors.selectors.ts';
import { useAppDispatch, useAppSelector } from '@/redux/hooks.ts';
import {
  GetUsersResponse,
  UpdateUserResponse,
} from '@/redux/users/users.types.ts';
import { formatCreationDate } from '@/utils/dateUtil.ts';

const userTableHeaders: TableHeaderType[] = [
  {
    id: 'full_name',
    text: 'Korisnik',
    className: '',
  },
  {
    id: 'place',
    text: 'Mesto',
    className: '',
  },
  { id: 'email', text: 'Email', className: 'max-md:hidden' },
  { id: 'phoneNumber', text: 'Telefon', className: 'max-md:hidden' },
  { id: 'parcelCount', text: 'Broj parcela', className: 'max-md:hidden' },
  { id: 'createdAt', text: 'Datum kreiranja', className: 'max-md:hidden' },
  { id: '', text: '', className: '' },
];

const UsersForDistributorSection: FC = () => {
  const params = useParams();
  const router = useNavigate();
  const users = useAppSelector(getUsersForDistributor);
  const totalNumberOfUsers = useAppSelector(
    getTotalNumberOfUsersForDistributors
  );
  const filteredNumberOfUsers = useAppSelector(
    getFilteredNumberOfUsersForDistributors
  );
  const dispatch = useAppDispatch();
  const [page, setPage] = useState<number>(0);
  const rowsPerPage = 10;
  const [sortColumn, setSortColumn] = useState<string>('');
  const [isSortAsc, setIsSortAsc] = useState<boolean>(true);
  const [inputText, setInputText] = useState<string>('');

  const createQueryParams = (
    realPage: number,
    realSortColumn: string,
    realSortAsc: boolean,
    realInputText: string
  ) => {
    return `?id=${params.id}&page=${realPage}&perPage=${rowsPerPage}${realSortColumn ? `&sortColumn=${realSortColumn}&sortDirection=${realSortAsc ? 'ASC' : 'DESC'}` : ''}${realInputText ? `&inputText=${realInputText}` : ''}`;
  };

  const callFetchUsers = async (queryParams: string) => {
    // @ts-ignore
    return dispatch(getFilteredUsersForDistributor(queryParams)).unwrap();
  };

  const handleErrorResponse = (
    response: GetUsersResponse | UpdateUserResponse
  ) => {
    if (response.error.removeUser) {
      localStorage.removeItem('token');
      localStorage.removeItem('name');
      dispatch(logoutState());
      dispatch(setName(''));
      router('/login');
      return;
    }

    toast.error(response.error.message);
  };

  const onDebounceCall = async (value: string) => {
    setInputText(value);
    setPage(0);
    const queryParams = createQueryParams(0, sortColumn, isSortAsc, value);
    await callFetchUsers(queryParams);
  };

  const debouncedChangeHandler = debounce((value: string) => {
    onDebounceCall(value);
  }, 500);

  const handleChange = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { value } = e.target;
    debouncedChangeHandler(value);
  };

  const onColumnClick = async (columnName: string) => {
    if (columnName === sortColumn) {
      setIsSortAsc(!isSortAsc);
      const queryParams = createQueryParams(
        page,
        sortColumn,
        !isSortAsc,
        inputText
      );
      await callFetchUsers(queryParams);
      return;
    }

    setSortColumn(columnName);
    setIsSortAsc(true);
    const queryParams = createQueryParams(page, columnName, true, inputText);
    await callFetchUsers(queryParams);
  };

  const onPageChangeClick = async (selectedPage: number) => {
    const queryParams = createQueryParams(
      selectedPage,
      sortColumn,
      isSortAsc,
      inputText
    );
    setPage(selectedPage);
    await callFetchUsers(queryParams);
  };

  const onRemoveUserClick = async (userId: string) => {
    // @ts-ignore
    const response = await dispatch(
      removeDistributorForUser({ userId })
    ).unwrap();

    if (!response.success) {
      handleErrorResponse(response);
      return;
    }

    toast.success('Uspešno uklonjen distributer za korisnika');
  };

  useEffect(() => {
    const fetchUsers = async () => {
      const queryParams = createQueryParams(
        page,
        sortColumn,
        isSortAsc,
        inputText
      );
      const response = await callFetchUsers(queryParams);
      if (!response.success) {
        handleErrorResponse(response);
      }
    };

    fetchUsers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [totalNumberOfUsers]);

  return (
    <>
      <div className="pt-5 px-5 pb-2 flex flex-row items-center justify-end gap-5">
        <p>Pretraga:</p>
        <div>
          <Input className="rounded-0" onChange={handleChange} />
        </div>
      </div>
      <div className="border-b border-gray-900/10 pt-5 md:px-5 text-sm">
        <div className="flex flex-col">
          <Table>
            <CustomTableHeader
              tableName="users"
              tableHeaders={userTableHeaders}
              onHeaderCellClick={onColumnClick}
            />
            <TableBody>
              {users.map((user, index) => (
                <TableRow key={`user-table-distributor-data-user-${index + 1}`}>
                  <TableCell>{user.full_name}</TableCell>
                  <TableCell>{user.place}</TableCell>
                  <TableCell className="max-md:hidden">{user.email}</TableCell>
                  <TableCell className="max-md:hidden">
                    {user.phoneNumber}
                  </TableCell>
                  <TableCell className="max-md:hidden">0</TableCell>
                  <TableCell className="max-md:hidden">
                    {formatCreationDate(new Date(user.createdAt))}
                  </TableCell>
                  <TableCell>
                    <Button
                      size="icon"
                      onClick={() => onRemoveUserClick(user.id)}
                    >
                      <FontAwesomeIcon
                        icon={faTrash}
                        color="red"
                        className="w-full h-4"
                      />
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </div>
      </div>
      <PaginationSection
        page={page}
        rowsPerPage={rowsPerPage}
        filteredNumber={filteredNumberOfUsers}
        totalNumber={totalNumberOfUsers}
        onPageChangeClick={onPageChangeClick}
        entity="korisnika"
      />
    </>
  );
};

export default UsersForDistributorSection;
