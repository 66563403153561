import { z } from 'zod';

export const createUserSchema = z
  .object({
    name: z.string().min(1, {
      message: 'Ime je obavezno polje',
    }),
    surname: z.string().min(1, {
      message: 'Prezime je obavezno polje',
    }),
    email: z.string().email({
      message: 'Email nije validan',
    }),
    password: z.string().min(1, {
      message: 'Lozinka je obavezno polje',
    }),
    phoneNumber: z.string().min(1, {
      message: 'Telefon je obavezno polje',
    }),
    address: z.string().min(1, {
      message: 'Adresa je obavezno polje',
    }),
    place: z.string().min(1, {
      message: 'Mesto je obavezno polje',
    }),
    country: z.string().min(1, {
      message: 'Drzava je obavezno polje',
    }),
    nameOfTheCompany: z.string().min(1, {
      message:
        'Naziv privrednog društva ili poljoprivrednog gazdinstva je obavezno polje',
    }),
    pib: z.string().min(1, {
      message: 'PIB ili BGP je obavezno polje',
    }),
    mbr: z.string().min(1, {
      message: 'Maticni broj firme ili JMBG je obavezno polje',
    }),
    dateOfPayment: z.date({
      required_error: 'Datum plaćanja pretplate je obavezan',
    }),
    differentBillingAddress: z.boolean(),
    billingAddress: z.string().optional(),
    billingPlace: z.string().optional(),
    billingCountry: z.string().optional(),
  })
  .refine(
    (data) => {
      if (data.differentBillingAddress) {
        return data.billingAddress?.trim().length > 0;
      }
      return true;
    },
    {
      message: 'Adresa za naplatu je obavezno polje',
      path: ['billingAddress'],
    }
  )
  .refine(
    (data) => {
      if (data.differentBillingAddress) {
        return data.billingPlace?.trim().length > 0;
      }
      return true;
    },
    {
      message: 'Mesto za naplatu je obavezno polje',
      path: ['billingPlace'],
    }
  )
  .refine(
    (data) => {
      if (data.differentBillingAddress) {
        return data.billingCountry?.trim().length > 0;
      }
      return true;
    },
    {
      message: 'Država za naplatu je obavezno polje',
      path: ['billingCountry'],
    }
  );

export const editUserSchema = z
  .object({
    id: z.string().min(1),
    name: z.string().min(1, {
      message: 'Ime je obavezno polje',
    }),
    surname: z.string().min(1, {
      message: 'Prezime je obavezno polje',
    }),
    email: z.string().email({
      message: 'Email nije validan',
    }),
    phoneNumber: z.string().min(1, {
      message: 'Telefon je obavezno polje',
    }),
    address: z.string().min(1, {
      message: 'Adresa je obavezno polje',
    }),
    place: z.string().min(1, {
      message: 'Mesto je obavezno polje',
    }),
    country: z.string().min(1, {
      message: 'Drzava je obavezno polje',
    }),
    nameOfTheCompany: z.string().min(1, {
      message:
        'Naziv privrednog društva ili poljoprivrednog gazdinstva je obavezno polje',
    }),
    pib: z.string().min(1, {
      message: 'PIB ili BGP je obavezno polje',
    }),
    mbr: z.string().min(1, {
      message: 'Maticni broj firme ili JMBG je obavezno polje',
    }),
    dateOfPayment: z.date({
      required_error: 'Datum plaćanja pretplate je obavezan',
    }),
    differentBillingAddress: z.boolean(),
    billingAddress: z.string().optional(),
    billingPlace: z.string().optional(),
    billingCountry: z.string().optional(),
  })
  .refine(
    (data) => {
      if (data.differentBillingAddress) {
        return data.billingAddress?.trim().length > 0;
      }
      return true;
    },
    {
      message: 'Adresa za naplatu je obavezno polje',
      path: ['billingAddress'],
    }
  )
  .refine(
    (data) => {
      if (data.differentBillingAddress) {
        return data.billingPlace?.trim().length > 0;
      }
      return true;
    },
    {
      message: 'Mesto za naplatu je obavezno polje',
      path: ['billingPlace'],
    }
  )
  .refine(
    (data) => {
      if (data.differentBillingAddress) {
        return data.billingCountry?.trim().length > 0;
      }
      return true;
    },
    {
      message: 'Država za naplatu je obavezno polje',
      path: ['billingCountry'],
    }
  );

export const loginSchema = z.object({
  username: z.string().min(1, {
    message: 'Korisnicko ime je obavezno polje',
  }),
  password: z.string().min(1, {
    message: 'Lozinka je obavezno polje',
  }),
});

export const createDistributorSchema = z.object({
  name: z.string().min(1, {
    message: 'Naziv je obavezno polje',
  }),
  email: z.string().email({
    message: 'Email nije validan',
  }),
  password: z.string().min(1, {
    message: 'Lozinka je obavezno polje',
  }),
  phoneNumber: z.string().min(1, {
    message: 'Telefon je obavezno polje',
  }),
  address: z.string().min(1, {
    message: 'Adresa je obavezno polje',
  }),
  place: z.string().min(1, {
    message: 'Mesto je obavezno polje',
  }),
  country: z.string().min(1, {
    message: 'Drzava je obavezno polje',
  }),
});

export const editDistributorSchema = z.object({
  id: z.string().min(1),
  name: z.string().min(1, {
    message: 'Naziv je obavezno polje',
  }),
  email: z.string().email({
    message: 'Email nije validan',
  }),
  phoneNumber: z.string().min(1, {
    message: 'Telefon je obavezno polje',
  }),
  address: z.string().min(1, {
    message: 'Adresa je obavezno polje',
  }),
  place: z.string().min(1, {
    message: 'Mesto je obavezno polje',
  }),
  country: z.string().min(1, {
    message: 'Drzava je obavezno polje',
  }),
});

export const createFruitTypeSchema = z.object({
  name: z.string().min(1, {
    message: 'Ime vrste je obavezno polje',
  }),
});

export const createFruitSortSchema = z.object({
  name: z.string().min(1, {
    message: 'Ime sorte je obavezno polje',
  }),
  fruitTypeId: z.string().min(1, {
    message: 'Niste odabrali vrstu',
  }),
});

export const userForDistributorSchema = z.object({
  userId: z.string().min(1, {
    message: 'Niste odabrali korisnika',
  }),
  distributorId: z.string().min(1, {
    message: 'Nije zadat distributer',
  }),
});

export const createParcelFormData = z.object({
  userId: z.string().min(1, {
    message: 'Niste odabrali korisnika',
  }),
  name: z.string().min(1, {
    message: 'Naziv parcele je obavezno polje',
  }),
  numberOfZones: z.string().refine((val) => !Number.isNaN(parseInt(val, 10)), {
    message: 'Broj zona mora biti pozitivan broj veći ili jednak 1',
  }),
  place: z.string().min(1, {
    message: 'Mesto je obavezno polje',
  }),
  weatherForecast: z.string().min(1, {
    message: 'Niste odabrali vremensku prognozu',
  }),
  simCardNumber: z.string().min(1, {
    message: 'Broj SIM kartice je obavezno polje',
  }),
  boundaryForDryLand: z
    .string()
    .refine((val) => !Number.isNaN(parseInt(val, 10)), {
      message: 'Granica mora biti pozitivan broj',
    }),
  boundaryForWetlands: z
    .string()
    .refine((val) => !Number.isNaN(parseInt(val, 10)), {
      message: 'Granica mora biti pozitivan broj',
    }),
  humiditySensor: z.boolean(),
  secondHumiditySensor: z.boolean(),
  supplementMixer: z.boolean(),
  supplement: z.boolean(),
  voltage: z.boolean(),
  rawSensorValues: z.boolean(),
});

export const editParcelSchema = z.object({
  id: z.string().min(1),
  userId: z.string().min(1, {
    message: 'Niste odabrali korisnika',
  }),
  name: z.string().min(1, {
    message: 'Naziv parcele je obavezno polje',
  }),
  numberOfZones: z.string().refine((val) => !Number.isNaN(parseInt(val, 10)), {
    message: 'Broj zona mora biti pozitivan broj veći ili jednak 1',
  }),
  maxBetweenTwoShifts: z
    .string()
    .refine((val) => !Number.isNaN(parseInt(val, 10)), {
      message:
        'Max vreme izmedju dve smene mora biti pozitivan broj veći ili jednak 1',
    }),
  place: z.string().min(1, {
    message: 'Mesto je obavezno polje',
  }),
  weatherForecast: z.string().min(1, {
    message: 'Niste odabrali vremensku prognozu',
  }),
  simCardNumber: z.string().min(1, {
    message: 'Broj SIM kartice je obavezno polje',
  }),
  boundaryForDryLand: z
    .string()
    .refine((val) => !Number.isNaN(parseInt(val, 10)), {
      message: 'Granica mora biti pozitivan broj',
    }),
  boundaryForWetlands: z
    .string()
    .refine((val) => !Number.isNaN(parseInt(val, 10)), {
      message: 'Granica mora biti pozitivan broj',
    }),
  coordinates: z.string(),
  humiditySensor: z.boolean(),
  secondHumiditySensor: z.boolean(),
  supplementMixer: z.boolean(),
  supplement: z.boolean(),
  voltage: z.boolean(),
  rawSensorValues: z.boolean(),
  numberOfIrrigation: z.boolean(),
  airHumidity: z.boolean(),
  pumpSensor: z.boolean(),
  numberOfZonesSwitch: z.boolean(),
  airTemperature: z.boolean(),
  soilTemperatureSensor: z.boolean(),
  soilSalinity: z.boolean(),
  flowMeter: z.boolean(),
  waterLevelSensor: z.boolean(),
  pHSensor: z.boolean(),
  moreSENSSensors: z.boolean(),
  flowMeterCapacity: z
    .string()
    .refine((val) => !Number.isNaN(parseInt(val, 10)), {
      message: 'Kapacitet protokomera mora biti broj',
    }),
  flowMeterFactorK: z
    .string()
    .refine((val) => !Number.isNaN(parseInt(val, 10)), {
      message: 'Faktor K protokomera mora biti broj',
    }),
  flowMeterFactorM: z
    .string()
    .refine((val) => !Number.isNaN(parseInt(val, 10)), {
      message: 'Fakot M protokomera mora biti broj',
    }),
  pHFactorK: z.string().refine((val) => !Number.isNaN(parseInt(val, 10)), {
    message: 'pH fakot K mora biti broj',
  }),
  minHumidityForAutomaticIrrigation: z
    .string()
    .refine((val) => !Number.isNaN(parseInt(val, 10)), {
      message:
        'Minimalna vlažnost za automatski režim navodnjavanja mora biti broj',
    }),
  optimalHumidityForAutomaticIrrigation: z
    .string()
    .refine((val) => !Number.isNaN(parseInt(val, 10)), {
      message:
        'Optimalna vlažnost za automatski režim navodnjavanja mora biti broj',
    }),
  durationOfTheActiveValve: z
    .string()
    .refine((val) => !Number.isNaN(parseInt(val, 10)), {
      message: 'Trajanje uključenog ventila mora biti broj',
    }),
  timeBetweenValves: z
    .string()
    .refine((val) => !Number.isNaN(parseInt(val, 10)), {
      message: 'Vreme izmedju ventila kod ciklusa mora biti broj',
    }),
});

export const createFERTDeviceSchema = z.object({
  parcelId: z.string().min(1, {
    message: 'Id parcele nije zadat',
  }),
  name: z.string().min(1, {
    message: 'Naziv uredjaja je obavezno polje',
  }),
  color: z.string().min(1, {
    message: 'Boja uredjaja je obavezno polje',
  }),
  communicationModule: z.string().min(1, {
    message: 'Niste odabrali komunikacioni modul',
  }),
  zonesForDevice: z
    .string()
    .array()
    .refine((val) => val.length > 0, {
      message: 'Uredjaj mora upravljati barem jednom zonom',
    })
    .refine((val) => val.length <= 16, {
      message: 'FERT uredjaj ne može upravljati više od 16 zona',
    }),
  pHFactorK: z.string().refine((val) => !Number.isNaN(parseInt(val, 10)), {
    message: 'pH faktor K mora biti broj',
  }),
  pHFactorM: z.string().refine((val) => !Number.isNaN(parseInt(val, 10)), {
    message: 'ph faktor M mora biti broj',
  }),
  ecFactorK: z.string().refine((val) => !Number.isNaN(parseInt(val, 10)), {
    message: 'Kalibracioni koeficijent K za EC',
  }),
  supplementOne: z.string(),
  supplementTwo: z.string(),
  supplementThree: z.string(),
  supplementFour: z.string(),
  supplementFive: z.string(),
  supplementMixer: z.string(),
  ecSensor: z.string(),
  flowMeter: z.string(),
  notificationForWrongFlowMeter: z.string(),
  pressureSensor: z.string(),
  waterLevelSensor: z.string(),
});

export const createStartDeviceSchema = z.object({
  parcelId: z.string().min(1, {
    message: 'Id parcele nije zadat',
  }),
  name: z.string().min(1, {
    message: 'Naziv uredjaja je obavezno polje',
  }),
  color: z.string().min(1, {
    message: 'Boja uredjaja je obavezno polje',
  }),
  zonesForDevice: z
    .string()
    .array()
    .refine((val) => val.length > 0, {
      message: 'Uredjaj mora upravljati barem jednom zonom',
    })
    .refine((val) => val.length <= 8, {
      message: 'START uredjaj ne može upravljati više od 8 zona',
    }),
  humidityOne: z.string(),
  humidityTwo: z.string(),
  supplementMixer: z.string(),
  notificationForWrongFlowMeter: z.string(),
  pressureSensor: z.string(),
  flowMeter: z.string(),
});

export const createSensDeviceSchema = z.object({
  parcelId: z.string().min(1, {
    message: 'Id parcele nije zadat',
  }),
  name: z.string().min(1, {
    message: 'Naziv uredjaja je obavezno polje',
  }),
  color: z.string().min(1, {
    message: 'Boja uredjaja je obavezno polje',
  }),
  sensSensorType: z.string().min(1, {
    message: 'Niste odabrali komunikacioni modul',
  }),
  zonesForDevice: z
    .string()
    .array()
    .refine((val) => val.length > 0, {
      message: 'Uredjaj mora upravljati barem jednom zonom',
    })
    .refine((val) => val.length <= 4, {
      message: 'SENS uredjaj ne može upravljati više od 4 zone',
    }),
  standbyModeTime: z
    .string()
    .refine((val) => !Number.isNaN(parseInt(val, 10)), {
      message: 'pH faktor K mora biti broj',
    }),
  humidityOne: z.string(),
  humidityTwo: z.string(),
  flowMeter: z.string(),
  soilTemperature: z.string(),
  soilSalinity: z.string(),
  soilPH: z.string(),
  airTemperature: z.string(),
  airHumidity: z.string(),
  windDirection: z.string(),
  windSpeed: z.string(),
  solarRadiation: z.string(),
  amountOfPrecipitation: z.string(),
  batteryVoltage: z.string(),
  notificationForWrongFlowMeter: z.string(),
  pressureSensor: z.string(),
});

export const editStartDeviceSchema = createStartDeviceSchema.extend({
  id: z.string().min(1),
});

export const editFERTDeviceSchema = createFERTDeviceSchema.extend({
  id: z.string().min(1),
});

export const editSensDeviceSchema = createSensDeviceSchema.extend({
  id: z.string().min(1),
});

export const selectParcelForUserSchema = z.object({
  parcelId: z.string().min(1, {
    message: 'Parcela nije odabrana',
  }),
});
