import { FC } from 'react';
import { UseFormReturn } from 'react-hook-form';

import { DropDownItems } from '@/components/controls/CustomFormDropDown.tsx';
import {
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@/components/ui/form.tsx';
import { RadioGroup, RadioGroupItem } from '@/components/ui/radio-group.tsx';

type CustomFormRadioGroupProps = {
  customForm: UseFormReturn<any, any, any>;
  name: string;
  labelText: string;
  formItemStyle?: string;
  items: DropDownItems[];
};

const CustomFormRadioGroup: FC<CustomFormRadioGroupProps> = ({
  customForm,
  name,
  labelText,
  formItemStyle,
  items,
}) => {
  return (
    <FormField
      control={customForm.control}
      name={name}
      render={({ field }) => (
        <FormItem className={formItemStyle}>
          <FormLabel className="text-base pt-5 px-2">{labelText}</FormLabel>
          <FormControl>
            <RadioGroup
              onValueChange={field.onChange}
              value={field.value}
              className="flex flex-col space-y-1 text-base mt-2 p-2 gap-2"
            >
              {items.map((item, idx) => (
                <FormItem
                  key={`radio-group-${name}-${item.value}-${idx + 1}`}
                  className="flex items-center space-x-3 space-y-0"
                >
                  <FormControl>
                    <RadioGroupItem value={item.value} />
                  </FormControl>
                  <FormLabel className="font-normal">{item.text}</FormLabel>
                </FormItem>
              ))}
            </RadioGroup>
          </FormControl>
          <FormMessage />
        </FormItem>
      )}
    />
  );
};

export default CustomFormRadioGroup;
