import { zodResolver } from '@hookform/resolvers/zod';
import { FC, useEffect, useState } from 'react';
import { useForm, UseFormReturn } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import CustomFormCombobox, {
  ComboboxItemType,
} from '@/components/controls/CustomFormCombobox.tsx';
import CustomFormInput from '@/components/controls/CustomFormInput.tsx';
import CustomFormSwitch from '@/components/controls/CustomFormSwitch.tsx';
import ConfirmDeleteDialog from '@/components/layout/ConfirmDeleteDialog.tsx';
import { Button } from '@/components/ui/button.tsx';
import { Form } from '@/components/ui/form.tsx';
import { logoutState, setName } from '@/redux/auth/auth.slice.ts';
import { useAppDispatch, useAppSelector } from '@/redux/hooks.ts';
import {
  deleteParcelById,
  updateParcel,
} from '@/redux/parcels/parcels.actions.ts';
import { CreateParcelResponse, Parcel } from '@/redux/parcels/parcels.types.ts';
import { getDropdownUsers } from '@/redux/users/user.selectors.ts';
import { createEditParcelStartData } from '@/utils/parcels.ts';
import { editParcelSchema } from '@/zod/types.ts';

export type EditParcelFormData = {
  id: string;
  userId: string;
  name: string;
  numberOfZones: string;
  maxBetweenTwoShifts: string;
  place: string;
  weatherForecast: string;
  simCardNumber: string;
  boundaryForDryLand: string;
  boundaryForWetlands: string;
  humiditySensor: boolean;
  secondHumiditySensor: boolean;
  supplementMixer: boolean;
  supplement: boolean;
  voltage: boolean;
  rawSensorValues: boolean;
  coordinates: string;
  numberOfIrrigation: boolean;
  airHumidity: boolean;
  pumpSensor: boolean;
  numberOfZonesSwitch: boolean;
  airTemperature: boolean;
  soilTemperatureSensor: boolean;
  soilSalinity: boolean;
  flowMeter: boolean;
  waterLevelSensor: boolean;
  pHSensor: boolean;
  flowMeterCapacity: string;
  flowMeterFactorK: string;
  flowMeterFactorM: string;
  pHFactorK: string;
  minHumidityForAutomaticIrrigation: string;
  optimalHumidityForAutomaticIrrigation: string;
  durationOfTheActiveValve: string;
  timeBetweenValves: string;
  moreSENSSensors: boolean;
};

type EditParcelFormButtonSectionProps = {
  onGiveUpClick: () => void;
  onDeleteParcelClick: () => void;
};

type EditParcelFormProps = {
  existingParcel: Parcel;
};

type EditParcelFormFirstSectionProps = {
  form: UseFormReturn<any, any, any>;
};

const EditParcelFormButtonSection: FC<EditParcelFormButtonSectionProps> = ({
  onGiveUpClick,
  onDeleteParcelClick,
}) => {
  return (
    <div className="flex flex-row max-md:flex-col w-full py-10">
      <span className="max-md:w-full w-[15%]" />
      <div className="w-[85%] max-md:w-full flex flex-row gap-5 max-md:flex-wrap">
        <Button
          className="bg-gray-600 text-white py-3 px-2"
          type="button"
          onClick={onGiveUpClick}
        >
          Odustani
        </Button>
        <Button className="bg-[#007bff] py-3 px-2 text-white" type="submit">
          Izmeni parcelu
        </Button>
        <Button
          className="bg-[#dc3545] py-3 px-2 text-white"
          onClick={onDeleteParcelClick}
          type="button"
        >
          Obriši parcelu
        </Button>
      </div>
    </div>
  );
};

const EditParcelFormFirstSection: FC<EditParcelFormFirstSectionProps> = ({
  form,
}) => {
  const usersForParcels = useAppSelector(getDropdownUsers);
  const comboboxContent: ComboboxItemType[] = usersForParcels.map((user) => {
    return {
      value: user.id,
      label: user.name,
    };
  });

  return (
    <>
      <CustomFormCombobox
        customForm={form}
        name="userId"
        labelText="Korisnik"
        formItemStyle="flex flex-row max-md:flex-col w-full border-b-2 border-dotted pb-10 max-md:py-5"
        labelStyle="max-md:w-full w-[15%]"
        buttonStyle="max-md:w-full w-[40%] px-3 py-2 justify-between"
        items={comboboxContent}
        placeholder="Odaberite korisnika"
      />
      <CustomFormInput
        customForm={form}
        name="name"
        labelText="Naziv parcele"
        formItemStyle="flex flex-row max-md:flex-col w-full border-b-2 border-dotted pb-10 py-10 max-md:py-5"
        labelStyle="max-md:w-full w-[15%]"
        divStyle="max-md:w-full w-[85%]"
        type="text"
      />
      <CustomFormInput
        customForm={form}
        name="numberOfZones"
        labelText="Broj zona"
        formItemStyle="flex flex-row max-md:flex-col w-full border-b-2 border-dotted pb-10 py-10 max-md:py-5"
        labelStyle="max-md:w-full w-[15%]"
        divStyle="max-md:w-full w-[85%]"
        type="number"
      />
      <CustomFormInput
        customForm={form}
        name="maxBetweenTwoShifts"
        labelText="Max vreme izmedju dve smene bez navodnjavanja u sekundama"
        formItemStyle="flex flex-row max-md:flex-col w-full border-b-2 border-dotted pb-10 py-10 max-md:py-5"
        labelStyle="max-md:w-full w-[15%]"
        divStyle="max-md:w-full w-[85%]"
        type="number"
      />
      <CustomFormInput
        customForm={form}
        name="place"
        labelText="Mesto"
        formItemStyle="flex flex-row max-md:flex-col w-full border-b-2 border-dotted pb-10 py-10 max-md:py-5"
        labelStyle="max-md:w-full w-[15%]"
        divStyle="max-md:w-full w-[85%]"
        type="text"
      />
      <CustomFormCombobox
        customForm={form}
        name="weatherForecast"
        labelText="Vremenska prognoza"
        formItemStyle="flex flex-row max-md:flex-col w-full border-b-2 border-dotted pb-10 py-10 max-md:py-5"
        labelStyle="max-md:w-full w-[15%]"
        buttonStyle="max-md:w-full w-[40%] px-3 py-2 justify-between"
        items={[{ value: 'Novi Sad', label: 'Novi Sad' }]}
        placeholder="Odaberite vremensku prognozu"
      />
      <CustomFormInput
        customForm={form}
        name="simCardNumber"
        labelText="Broj SIM kartice"
        formItemStyle="flex flex-row max-md:flex-col w-full border-b-2 border-dotted pb-10 py-10 max-md:py-5"
        labelStyle="max-md:w-full w-[15%]"
        divStyle="max-md:w-full w-[85%]"
        type="text"
      />
    </>
  );
};

const EditParcelFormSecondSection: FC<EditParcelFormFirstSectionProps> = ({
  form,
}) => {
  return (
    <>
      <CustomFormSwitch
        customForm={form}
        name="humiditySensor"
        labelText="Senzor vlažnosti"
        formItemStyle="flex flex-row max-md:flex-col w-full border-b-2 border-dotted pb-10 pt-5 max-md:py-5 gap-2 md:items-center"
        labelStyle="max-md:w-full text-sm md:text-center"
      />
      <CustomFormSwitch
        customForm={form}
        name="secondHumiditySensor"
        labelText="Drugi senzor vlažnosti"
        formItemStyle="flex flex-row max-md:flex-col w-full border-b-2 border-dotted pb-10 pt-5 max-md:py-5 gap-2 md:items-center"
        labelStyle="max-md:w-full text-sm md:text-center"
      />
      <CustomFormSwitch
        customForm={form}
        name="supplementMixer"
        labelText="Mikser prihrane"
        formItemStyle="flex flex-row max-md:flex-col w-full border-b-2 border-dotted pb-10 pt-5 max-md:py-5 gap-2 md:items-center"
        labelStyle="max-md:w-full text-sm md:text-center"
      />
      <CustomFormSwitch
        customForm={form}
        name="supplement"
        labelText="Prihrana"
        formItemStyle="flex flex-row max-md:flex-col w-full border-b-2 border-dotted pb-10 pt-5 max-md:py-5 gap-2 md:items-center"
        labelStyle="max-md:w-full text-sm md:text-center"
      />
      <CustomFormSwitch
        customForm={form}
        name="voltage"
        labelText="Napon"
        formItemStyle="flex flex-row max-md:flex-col w-full border-b-2 border-dotted pb-10 pt-5 max-md:py-5 gap-2 md:items-center"
        labelStyle="max-md:w-full text-sm md:text-center"
      />
      <CustomFormSwitch
        customForm={form}
        name="numberOfIrrigation"
        labelText="Broj navodnjavanja"
        formItemStyle="flex flex-row max-md:flex-col w-full border-b-2 border-dotted pb-10 pt-5 max-md:py-5 gap-2 md:items-center"
        labelStyle="max-md:w-full text-sm md:text-center"
      />
      <CustomFormSwitch
        customForm={form}
        name="airHumidity"
        labelText="Vlažnost vazduha"
        formItemStyle="flex flex-row max-md:flex-col w-full border-b-2 border-dotted pb-10 pt-5 max-md:py-5 gap-2 md:items-center"
        labelStyle="max-md:w-full text-sm md:text-center"
      />
    </>
  );
};

const EditParcelFormThirdSection: FC<EditParcelFormFirstSectionProps> = ({
  form,
}) => {
  return (
    <>
      <CustomFormSwitch
        customForm={form}
        name="pumpSensor"
        labelText="Senzor pumpe"
        formItemStyle="flex flex-row max-md:flex-col w-full border-b-2 border-dotted pb-10 pt-5 max-md:py-5 gap-2 md:items-center"
        labelStyle="max-md:w-full text-sm md:text-center"
      />
      <CustomFormSwitch
        customForm={form}
        name="numberOfZonesSwitch"
        labelText="Broj zona"
        formItemStyle="flex flex-row max-md:flex-col w-full border-b-2 border-dotted pb-10 pt-5 max-md:py-5 gap-2 md:items-center"
        labelStyle="max-md:w-full text-sm md:text-center"
      />
      <CustomFormSwitch
        customForm={form}
        name="airTemperature"
        labelText="Temperatura vazduha"
        formItemStyle="flex flex-row max-md:flex-col w-full border-b-2 border-dotted pb-10 pt-5 max-md:py-5 gap-2 md:items-center"
        labelStyle="max-md:w-full text-sm md:text-center"
      />
      <CustomFormSwitch
        customForm={form}
        name="rawSensorValues"
        labelText="Neobradjene vrednosti senzora"
        formItemStyle="flex flex-row max-md:flex-col w-full border-b-2 border-dotted pb-10 pt-5 max-md:py-5 gap-2 md:items-center"
        labelStyle="max-md:w-full text-sm md:text-center"
      />
      <CustomFormInput
        customForm={form}
        name="boundaryForDryLand"
        labelText="Granica - suvo zemljište"
        formItemStyle="flex flex-row max-md:flex-col w-full border-b-2 border-dotted pb-10 py-10 max-md:py-5"
        labelStyle="max-md:w-full w-[15%]"
        divStyle="max-md:w-full w-[85%]"
        type="number"
      />
      <CustomFormInput
        customForm={form}
        name="boundaryForWetlands"
        labelText="Granica - vlažno zemljište"
        formItemStyle="flex flex-row max-md:flex-col w-full border-b-2 border-dotted pb-10 py-10 max-md:py-5"
        labelStyle="max-md:w-full w-[15%]"
        divStyle="max-md:w-full w-[85%]"
        type="number"
      />
      <CustomFormSwitch
        customForm={form}
        name="soilTemperatureSensor"
        labelText="Senzor temperature zemljišta"
        formItemStyle="flex flex-row max-md:flex-col w-full border-b-2 border-dotted pb-10 pt-5 max-md:py-5 gap-2 md:items-center"
        labelStyle="max-md:w-full text-sm md:text-center"
      />
      <CustomFormSwitch
        customForm={form}
        name="soilSalinity"
        labelText="Salinitet zemljišta"
        formItemStyle="flex flex-row max-md:flex-col w-full border-b-2 border-dotted pb-10 pt-5 max-md:py-5 gap-2 md:items-center"
        labelStyle="max-md:w-full text-sm md:text-center"
      />
      <CustomFormSwitch
        customForm={form}
        name="flowMeter"
        labelText="Protokomer"
        formItemStyle="flex flex-row max-md:flex-col w-full border-b-2 border-dotted pb-10 pt-5 max-md:py-5 gap-2 md:items-center"
        labelStyle="max-md:w-full text-sm md:text-center"
      />
      <CustomFormSwitch
        customForm={form}
        name="waterLevelSensor"
        labelText="Senzor nivoa vode"
        formItemStyle="flex flex-row max-md:flex-col w-full border-b-2 border-dotted pb-10 pt-5 max-md:py-5 gap-2 md:items-center"
        labelStyle="max-md:w-full text-sm md:text-center"
      />
      <CustomFormSwitch
        customForm={form}
        name="pHSensor"
        labelText="pH senzor"
        formItemStyle="flex flex-row max-md:flex-col w-full border-b-2 border-dotted pb-10 pt-5 max-md:py-5 gap-2 md:items-center"
        labelStyle="max-md:w-full text-sm md:text-center"
      />
    </>
  );
};

const EditParcelFormFourthSection: FC<EditParcelFormFirstSectionProps> = ({
  form,
}) => {
  return (
    <>
      <CustomFormInput
        customForm={form}
        name="flowMeterCapacity"
        labelText="Kapacitet protokomera"
        formItemStyle="flex flex-row max-md:flex-col w-full border-b-2 border-dotted pb-10 py-10 max-md:py-5"
        labelStyle="max-md:w-full w-[15%]"
        divStyle="max-md:w-full w-[85%]"
        type="number"
      />
      <CustomFormInput
        customForm={form}
        name="flowMeterFactorK"
        labelText="Faktor K protokomera"
        formItemStyle="flex flex-row max-md:flex-col w-full border-b-2 border-dotted pb-10 py-10 max-md:py-5"
        labelStyle="max-md:w-full w-[15%]"
        divStyle="max-md:w-full w-[85%]"
        type="number"
      />
      <CustomFormInput
        customForm={form}
        name="flowMeterFactorM"
        labelText="Fakotr M protokomera"
        formItemStyle="flex flex-row max-md:flex-col w-full border-b-2 border-dotted pb-10 py-10 max-md:py-5"
        labelStyle="max-md:w-full w-[15%]"
        divStyle="max-md:w-full w-[85%]"
        type="number"
      />
      <CustomFormInput
        customForm={form}
        name="pHFactorK"
        labelText="pH faktor K"
        formItemStyle="flex flex-row max-md:flex-col w-full border-b-2 border-dotted pb-10 py-10 max-md:py-5"
        labelStyle="max-md:w-full w-[15%]"
        divStyle="max-md:w-full w-[85%]"
        type="number"
      />
      <CustomFormInput
        customForm={form}
        name="minHumidityForAutomaticIrrigation"
        labelText="Minimalna vlažnost za automatski režim navodnjavanja (%)"
        formItemStyle="flex flex-row max-md:flex-col w-full border-b-2 border-dotted pb-10 py-10 max-md:py-5"
        labelStyle="max-md:w-full w-[15%]"
        divStyle="max-md:w-full w-[85%]"
        type="number"
      />
      <CustomFormInput
        customForm={form}
        name="optimalHumidityForAutomaticIrrigation"
        labelText="Optimalna vlažnost za automatski režim navodnjavanja (%)"
        formItemStyle="flex flex-row max-md:flex-col w-full border-b-2 border-dotted pb-10 py-10 max-md:py-5"
        labelStyle="max-md:w-full w-[15%]"
        divStyle="max-md:w-full w-[85%]"
        type="number"
      />
      <CustomFormInput
        customForm={form}
        name="durationOfTheActiveValve"
        labelText="Trajanje uključenog ventila (ms)"
        formItemStyle="flex flex-row max-md:flex-col w-full border-b-2 border-dotted pb-10 py-10 max-md:py-5"
        labelStyle="max-md:w-full w-[15%]"
        divStyle="max-md:w-full w-[85%]"
        type="number"
      />
      <CustomFormInput
        customForm={form}
        name="timeBetweenValves"
        labelText="Vreme između ventila kod ciklusnog (s)"
        formItemStyle="flex flex-row max-md:flex-col w-full border-b-2 border-dotted pb-10 py-10 max-md:py-5"
        labelStyle="max-md:w-full w-[15%]"
        divStyle="max-md:w-full w-[85%]"
        type="number"
      />
      <CustomFormInput
        customForm={form}
        name="coordinates"
        labelText="Koordinate"
        formItemStyle="flex flex-row max-md:flex-col w-full border-b-2 border-dotted pb-10 py-10 max-md:py-5"
        labelStyle="max-md:w-full w-[15%]"
        divStyle="max-md:w-full w-[85%]"
        type="text"
      />
      <CustomFormSwitch
        customForm={form}
        name="moreSENSSensors"
        labelText="Više SENS senzora"
        formItemStyle="flex flex-row max-md:flex-col w-full border-b-2 border-dotted pb-10 pt-5 max-md:py-5 gap-2 md:items-center"
        labelStyle="max-md:w-full text-sm md:text-center"
      />
    </>
  );
};

const EditParcelForm: FC<EditParcelFormProps> = ({ existingParcel }) => {
  const editForm = useForm({
    resolver: zodResolver(editParcelSchema),
    defaultValues: { ...createEditParcelStartData() },
  });
  const dispatch = useAppDispatch();
  const router = useNavigate();
  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);

  const handleErrorResponse = (response: CreateParcelResponse) => {
    if (response.error.removeUser) {
      localStorage.removeItem('token');
      localStorage.removeItem('name');
      dispatch(logoutState());
      dispatch(setName(''));
      router('/login');
      return;
    }

    toast.error(response.error.message);
  };

  const onGiveUpClick = () => {
    router(-1);
  };

  const onSubmit = async (data: EditParcelFormData) => {
    // @ts-ignore
    const response = await dispatch(updateParcel(data)).unwrap();

    if (!response.success) {
      handleErrorResponse(response);
      return;
    }

    toast.success('Parcela je uspešno izmenjena');
    router(-1);
  };

  const onDeleteClick = () => {
    setIsDialogOpen(true);
  };

  const onDeleteParcelClick = async () => {
    // @ts-ignore
    const response = await dispatch(
      deleteParcelById(existingParcel.id)
    ).unwrap();
    if (!response.success) {
      handleErrorResponse(response);
      return;
    }

    toast.success('Parcela je uspešno izbrisana');
    router(-1);
  };

  const setEditFormBasicSensorValues = () => {
    editForm.setValue(
      'rawSensorValues',
      existingParcel.basicSensorsValues.rawSensorValues
    );
    editForm.setValue(
      'humiditySensor',
      existingParcel.basicSensorsValues.humiditySensor
    );
    editForm.setValue(
      'secondHumiditySensor',
      existingParcel.basicSensorsValues.secondHumiditySensor
    );
    editForm.setValue('voltage', existingParcel.basicSensorsValues.voltage);
    editForm.setValue(
      'supplement',
      existingParcel.basicSensorsValues.supplement
    );
    editForm.setValue(
      'supplementMixer',
      existingParcel.basicSensorsValues.supplementMixer
    );
  };

  const setEditFormAdditionalSensorValues = () => {
    editForm.setValue(
      'pHSensor',
      existingParcel.additionalSensorsValues.pHSensor
    );
    editForm.setValue(
      'pumpSensor',
      existingParcel.additionalSensorsValues.pumpSensor
    );
    editForm.setValue(
      'waterLevelSensor',
      existingParcel.additionalSensorsValues.waterLevelSensor
    );
    editForm.setValue(
      'moreSENSSensors',
      existingParcel.additionalSensorsValues.moreSENSSensors
    );
    editForm.setValue(
      'soilTemperatureSensor',
      existingParcel.additionalSensorsValues.soilTemperatureSensor
    );
    editForm.setValue(
      'airHumidity',
      existingParcel.additionalSensorsValues.airHumidity
    );
    editForm.setValue(
      'flowMeter',
      existingParcel.additionalSensorsValues.flowMeter
    );
    editForm.setValue(
      'timeBetweenValves',
      existingParcel.additionalSensorsValues.timeBetweenValves.toString()
    );
    editForm.setValue(
      'soilSalinity',
      existingParcel.additionalSensorsValues.soilSalinity
    );
    editForm.setValue(
      'optimalHumidityForAutomaticIrrigation',
      existingParcel.additionalSensorsValues.optimalHumidityForAutomaticIrrigation.toString()
    );
    editForm.setValue(
      'durationOfTheActiveValve',
      existingParcel.additionalSensorsValues.durationOfTheActiveValve.toString()
    );
    editForm.setValue(
      'pHFactorK',
      existingParcel.additionalSensorsValues.pHFactorK.toString()
    );
    editForm.setValue(
      'flowMeterFactorM',
      existingParcel.additionalSensorsValues.flowMeterFactorM.toString()
    );
    editForm.setValue(
      'flowMeterCapacity',
      existingParcel.additionalSensorsValues.flowMeterCapacity.toString()
    );
    editForm.setValue(
      'numberOfIrrigation',
      existingParcel.additionalSensorsValues.numberOfIrrigation
    );
    editForm.setValue(
      'numberOfZonesSwitch',
      existingParcel.additionalSensorsValues.numberOfZones
    );
    editForm.setValue(
      'airTemperature',
      existingParcel.additionalSensorsValues.airTemperature
    );
    editForm.setValue(
      'flowMeterFactorK',
      existingParcel.additionalSensorsValues.flowMeterFactorK.toString()
    );
    editForm.setValue(
      'minHumidityForAutomaticIrrigation',
      existingParcel.additionalSensorsValues.minHumidityForAutomaticIrrigation.toString()
    );
  };

  useEffect(() => {
    editForm.setValue('id', existingParcel.id);
    editForm.setValue('name', existingParcel.name);
    editForm.setValue(
      'boundaryForDryLand',
      existingParcel.boundaryForDryLand.toString()
    );
    editForm.setValue(
      'boundaryForWetlands',
      existingParcel.boundaryForWetlands.toString()
    );
    editForm.setValue(
      'coordinates',
      existingParcel.coordinates ? existingParcel.coordinates : ''
    );
    editForm.setValue('place', existingParcel.place);
    editForm.setValue(
      'maxBetweenTwoShifts',
      existingParcel.maxBetweenTwoShifts.toString()
    );
    editForm.setValue('numberOfZones', existingParcel.numberOfZones.toString());
    editForm.setValue('simCardNumber', existingParcel.simCardNumber);
    editForm.setValue('userId', existingParcel.userId);
    editForm.setValue('weatherForecast', existingParcel.weatherForecast);
    setEditFormBasicSensorValues();
    setEditFormAdditionalSensorValues();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [existingParcel]);

  return (
    <div className="flex w-[88%] flex-col bg-[#85b4f2] bg-opacity-10 max-md:w-full">
      <nav className="flex itemst-start bg-white border-b-2 w-full px-10 py-5 max-md:px-5">
        <div className="text-3xl font-bold">Izmeni parcelu</div>
      </nav>
      <Form
        reset={editForm.reset}
        formState={editForm.formState}
        clearErrors={editForm.clearErrors}
        control={editForm.control}
        getFieldState={editForm.getFieldState}
        getValues={editForm.getValues}
        handleSubmit={editForm.handleSubmit}
        register={editForm.register}
        resetField={editForm.resetField}
        setError={editForm.setError}
        setFocus={editForm.setFocus}
        setValue={editForm.setValue}
        trigger={editForm.trigger}
        unregister={editForm.unregister}
        watch={editForm.watch}
      >
        <form
          className="flex items-start gap-6 p-10 max-md:p-5"
          onSubmit={editForm.handleSubmit(onSubmit)}
        >
          <div className="bg-white w-full shadow-lg">
            <nav className="flex itemst-start bg-white border-b-2 w-full px-5 py-3">
              <div className="text-xl font-bold">Detalji parcele</div>
            </nav>
            <div className="border-b border-gray-900/10 pt-5 px-5 text-sm">
              <div className="flex flex-col">
                <EditParcelFormFirstSection form={editForm} />
                <EditParcelFormSecondSection form={editForm} />
                <EditParcelFormThirdSection form={editForm} />
                <EditParcelFormFourthSection form={editForm} />
                <EditParcelFormButtonSection
                  onGiveUpClick={onGiveUpClick}
                  onDeleteParcelClick={onDeleteClick}
                />
              </div>
            </div>
          </div>
        </form>
      </Form>
      <ConfirmDeleteDialog
        headerText="Da li ste sigurni da želite da obrišete parcelu?"
        descriptionText="Ova akcija se ne može poništiti. Trajno ćete obrisati parcelu."
        onContinueClick={onDeleteParcelClick}
        isDialogOpen={isDialogOpen}
        setIsDialogOpen={setIsDialogOpen}
      />
    </div>
  );
};

export default EditParcelForm;
