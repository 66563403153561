import { RootState } from '@/redux/store.ts';
import {
  FetchUser,
  ParcelForUser,
  UserForDropdown,
} from '@/redux/users/users.types.ts';

export const getUsers = (state: RootState): FetchUser[] =>
  state.reducer.user.users || [];

export const getTotalNumberOfUsers = (state: RootState): number =>
  state.reducer.user.totalNumberOfUsers || 0;

export const getFilteredNumberOfUsers = (state: RootState): number =>
  state.reducer.user.filteredNumberOfUsers || 0;

export const getDropdownUsers = (state: RootState): UserForDropdown[] =>
  state.reducer.user.usersForDropdown || [];

export const getParcelIdsForUser = (state: RootState): ParcelForUser[] =>
  state.reducer.user.parcelsForUser || [];
