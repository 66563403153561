import { zodResolver } from '@hookform/resolvers/zod';
import { FC, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import CustomFormDatePicker from '@/components/controls/CustomFormDatePicker.tsx';
import CustomFormInput from '@/components/controls/CustomFormInput.tsx';
import BillingAddressForm from '@/components/forms/user/BillingAddressForm.tsx';
import ConfirmDeleteDialog from '@/components/layout/ConfirmDeleteDialog.tsx';
import { Button } from '@/components/ui/button.tsx';
import { Form } from '@/components/ui/form.tsx';
import { logoutState, setName } from '@/redux/auth/auth.slice.ts';
import { useAppDispatch } from '@/redux/hooks.ts';
import { deleteUser, updateUser } from '@/redux/users/users.actions.ts';
import {
  CreateUserResponse,
  UpdateUserResponse,
  User,
} from '@/redux/users/users.types.ts';
import { editUserSchema } from '@/zod/types.ts';

export type EditUserFormData = {
  id: string;
  name: string;
  surname: string;
  email: string;
  phoneNumber: string;
  address: string;
  place: string;
  country: string;
  nameOfTheCompany: string;
  pib: string;
  mbr: string;
  dateOfPayment: Date;
  differentBillingAddress: boolean;
  billingAddress: string;
  billingPlace: string;
  billingCountry: string;
};

type EditUserFormButtonSectionProps = {
  onGiveUpClick: () => void;
  onDeleteUserClick: () => void;
};

type EditUserFormProps = {
  existingUser: User;
};

const EditUserFormButtonSection: FC<EditUserFormButtonSectionProps> = ({
  onGiveUpClick,
  onDeleteUserClick,
}) => {
  return (
    <div className="flex flex-row max-md:flex-col w-full py-10">
      <span className="max-md:w-full w-[15%]" />
      <div className="w-[85%] max-md:w-full flex flex-row gap-5 max-md:flex-wrap">
        <Button
          className="bg-gray-600 text-white py-3 px-2"
          type="button"
          onClick={onGiveUpClick}
        >
          Odustani
        </Button>
        <Button className="bg-[#007bff] py-3 px-2 text-white" type="submit">
          Izmeni korisnika
        </Button>
        <Button
          className="bg-[#28a745] py-3 px-2 text-white"
          type="button"
          onClick={onGiveUpClick}
        >
          Pošalji račun
        </Button>
        <Button
          className="bg-[#dc3545] py-3 px-2 text-white"
          onClick={onDeleteUserClick}
          type="button"
        >
          Obriši korisnika
        </Button>
      </div>
    </div>
  );
};

const EditUserForm: FC<EditUserFormProps> = ({ existingUser }) => {
  const editForm = useForm({
    resolver: zodResolver(editUserSchema),
    defaultValues: {
      ...existingUser,
      dateOfPayment: new Date(),
      billingAddress: '',
      billingPlace: '',
      billingCountry: '',
    },
  });
  const dispatch = useAppDispatch();
  const router = useNavigate();
  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);
  const [isBillingDifferent, setIsBillingDifferent] = useState<boolean>(false);

  const handleErrorResponse = (
    response: CreateUserResponse | UpdateUserResponse
  ) => {
    if (response.error.removeUser) {
      localStorage.removeItem('token');
      localStorage.removeItem('name');
      dispatch(logoutState());
      dispatch(setName(''));
      router('/login');
      return;
    }

    toast.error(response.error.message);
  };

  const onGiveUpClick = () => {
    router('/users');
  };

  const onSubmit = async (data: EditUserFormData) => {
    // @ts-ignore
    const response = await dispatch(updateUser(data)).unwrap();

    if (!response.success) {
      handleErrorResponse(response);
      return;
    }

    toast.success('Korisnik je uspešno izmenjen');
    router('/users');
  };

  const onDeleteClick = () => {
    setIsDialogOpen(true);
  };

  const onDeleteUserClick = async () => {
    const userId = existingUser.id;
    // @ts-ignore
    const response = await dispatch(deleteUser({ userId })).unwrap();
    if (!response.success) {
      handleErrorResponse(response);
      return;
    }

    toast.success('Korisnik je uspešno izbrisan');
    router('/users');
  };

  useEffect(() => {
    editForm.setValue('id', existingUser.id);
    editForm.setValue('name', existingUser.name);
    editForm.setValue('surname', existingUser.surname);
    editForm.setValue('email', existingUser.email);
    editForm.setValue('phoneNumber', existingUser.phoneNumber);
    editForm.setValue('place', existingUser.place);
    editForm.setValue('address', existingUser.address);
    editForm.setValue('country', existingUser.country);
    editForm.setValue('nameOfTheCompany', existingUser.nameOfTheCompany);
    editForm.setValue('pib', existingUser.pib);
    editForm.setValue('mbr', existingUser.mbr);
    editForm.setValue(
      'dateOfPayment',
      existingUser.dateOfPayment
        ? new Date(existingUser.dateOfPayment)
        : new Date()
    );
    editForm.setValue(
      'differentBillingAddress',
      existingUser.differentBillingAddress
    );
    if (existingUser.differentBillingAddress) {
      editForm.setValue('billingAddress', existingUser.billingAddress);
      editForm.setValue('billingPlace', existingUser.billingPlace);
      editForm.setValue('billingCountry', existingUser.billingCountry);
      setIsBillingDifferent(existingUser.differentBillingAddress);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [existingUser]);

  useEffect(() => {
    setIsBillingDifferent(
      editForm.getValues('differentBillingAddress' as never)
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editForm.watch('differentBillingAddress' as never)]);

  return (
    <div className="flex w-[88%] flex-col bg-[#85b4f2] bg-opacity-10 max-md:w-full">
      <nav className="flex itemst-start bg-white border-b-2 w-full px-10 py-5 max-md:px-5">
        <div className="text-3xl font-bold">Izmeni korisnika</div>
      </nav>
      <Form
        reset={editForm.reset}
        formState={editForm.formState}
        clearErrors={editForm.clearErrors}
        control={editForm.control}
        getFieldState={editForm.getFieldState}
        getValues={editForm.getValues}
        handleSubmit={editForm.handleSubmit}
        register={editForm.register}
        resetField={editForm.resetField}
        setError={editForm.setError}
        setFocus={editForm.setFocus}
        setValue={editForm.setValue}
        trigger={editForm.trigger}
        unregister={editForm.unregister}
        watch={editForm.watch}
      >
        <form
          className="flex items-start gap-6 p-10 max-md:p-5"
          onSubmit={editForm.handleSubmit(onSubmit)}
        >
          <div className="bg-white w-full shadow-lg">
            <nav className="flex itemst-start bg-white border-b-2 w-full px-5 py-3">
              <div className="text-xl font-bold">Detalji korisnika</div>
            </nav>
            <div className="border-b border-gray-900/10 pt-5 px-5 text-sm">
              <div className=" flex flex-col">
                <CustomFormInput
                  customForm={editForm}
                  name="name"
                  labelText="Ime"
                  formItemStyle="flex flex-row max-md:flex-col w-full border-b-2 border-dotted pb-10 max-md:py-5"
                  labelStyle="max-md:w-full w-[15%]"
                  divStyle="max-md:w-full w-[85%]"
                  type="text"
                />
                <CustomFormInput
                  customForm={editForm}
                  name="surname"
                  labelText="Prezime"
                  formItemStyle="flex flex-row max-md:flex-col w-full border-b-2 border-dotted pb-10 py-10 max-md:py-5"
                  labelStyle="max-md:w-full w-[15%]"
                  divStyle="max-md:w-full w-[85%]"
                  type="text"
                />
                <CustomFormInput
                  customForm={editForm}
                  name="email"
                  labelText="Email"
                  formItemStyle="flex flex-row max-md:flex-col w-full border-b-2 border-dotted pb-10 py-10 max-md:py-5"
                  labelStyle="max-md:w-full w-[15%]"
                  divStyle="max-md:w-full w-[85%]"
                  type="text"
                />
                <CustomFormInput
                  customForm={editForm}
                  name="phoneNumber"
                  labelText="Telefon"
                  formItemStyle="flex flex-row max-md:flex-col w-full border-b-2 border-dotted pb-10 py-10 max-md:py-5"
                  labelStyle="max-md:w-full w-[15%]"
                  divStyle="max-md:w-full w-[85%]"
                  type="text"
                />
                <CustomFormInput
                  customForm={editForm}
                  name="address"
                  labelText="Adresa"
                  formItemStyle="flex flex-row max-md:flex-col w-full border-b-2 border-dotted pb-10 py-10 max-md:py-5"
                  labelStyle="max-md:w-full w-[15%]"
                  divStyle="max-md:w-full w-[85%]"
                  type="text"
                />
                <CustomFormInput
                  customForm={editForm}
                  name="place"
                  labelText="Mesto"
                  formItemStyle="flex flex-row max-md:flex-col w-full border-b-2 border-dotted pb-10 py-10 max-md:py-5"
                  labelStyle="max-md:w-full w-[15%]"
                  divStyle="max-md:w-full w-[85%]"
                  type="text"
                />
                <CustomFormInput
                  customForm={editForm}
                  name="country"
                  labelText="Drzava"
                  formItemStyle="flex flex-row max-md:flex-col w-full border-b-2 border-dotted pb-10 py-10 max-md:py-5"
                  labelStyle="max-md:w-full w-[15%]"
                  divStyle="max-md:w-full w-[85%]"
                  type="text"
                />
                <CustomFormInput
                  customForm={editForm}
                  name="nameOfTheCompany"
                  labelText="Naziv privrednog društva ili poljoprivrednog gazdinstva"
                  formItemStyle="flex flex-row max-md:flex-col w-full border-b-2 border-dotted pb-10 py-10 max-md:py-5"
                  labelStyle="max-md:w-full w-[15%]"
                  divStyle="max-md:w-full w-[85%]"
                  type="text"
                />
                <CustomFormInput
                  customForm={editForm}
                  name="pib"
                  labelText="PIB ili BGP"
                  formItemStyle="flex flex-row max-md:flex-col w-full border-b-2 border-dotted pb-10 py-10 max-md:py-5"
                  labelStyle="max-md:w-full w-[15%]"
                  divStyle="max-md:w-full w-[85%]"
                  type="text"
                />
                <CustomFormInput
                  customForm={editForm}
                  name="mbr"
                  labelText="Maticni broj firme ili JMBG"
                  formItemStyle="flex flex-row max-md:flex-col w-full border-b-2 border-dotted pb-10 py-10 max-md:py-5"
                  labelStyle="max-md:w-full w-[15%]"
                  divStyle="max-md:w-full w-[85%]"
                  type="text"
                />
                <CustomFormDatePicker
                  customForm={editForm}
                  name="dateOfPayment"
                  labelText="Datum plaćanja pretplate"
                  formItemStyle="flex flex-row max-md:flex-col w-full border-b-2 border-dotted pb-10 py-10 max-md:py-5"
                  labelStyle="max-md:w-full w-[15%]"
                  divStyle="max-md:w-full w-[85%]"
                  buttonStyle="max-md:w-full w-full py-3 px-5"
                />
                <BillingAddressForm
                  form={editForm}
                  isFormShown={isBillingDifferent}
                />
                <EditUserFormButtonSection
                  onGiveUpClick={onGiveUpClick}
                  onDeleteUserClick={onDeleteClick}
                />
              </div>
            </div>
          </div>
        </form>
      </Form>
      <ConfirmDeleteDialog
        headerText="Da li ste sigurni da želite da obrišete korisnika?"
        descriptionText="Ova akcija se ne može poništiti. Trajno ćete obrisati korisnika."
        onContinueClick={onDeleteUserClick}
        isDialogOpen={isDialogOpen}
        setIsDialogOpen={setIsDialogOpen}
      />
    </div>
  );
};

export default EditUserForm;
