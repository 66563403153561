import { FC } from 'react';

import CreateDeviceContent from '@/components/layout/devices/CreateDeviceContent.tsx';
import MobileFooterNavigation from '@/components/layout/MobileFooterNavigation.tsx';
import Navbar from '@/components/layout/Navbar.tsx';
import Sidebar from '@/components/layout/Sidebar.tsx';

const CreateDevicePage: FC = () => {
  return (
    <div className="flex h-screen w-full flex-col">
      <Navbar />
      <div className="flex flex-1 flex-row">
        <Sidebar />
        <CreateDeviceContent />
      </div>
      <MobileFooterNavigation />
    </div>
  );
};

export default CreateDevicePage;
